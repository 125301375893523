









import Vue, { PropType } from 'vue'
import UiVideos from 'robomow-ui/src/components/UiVideos'
import { PropsData } from 'robomow-ui/src/components/UiVideos/UiVideos.types'
import { Gallery } from './CeTextmedia.types'
import CeTextpic from './../CeTextpic'
import CeTextmedia from '~typo3/components/content/elements/CeTextmedia'

export default Vue.extend({
  components: {
    UiVideos,
    CeTextpic
  },
  extends: CeTextmedia,
  props: {
    header: {
      type: String,
      default: ''
    },
    headerLayout: {
      type: Number,
      default: 0
    },
    bodytext: {
      type: String,
      default: ''
    },
    gallery: {
      type: Object as PropType<Gallery>,
      required: true,
      default: () => { return {} as Gallery }
    },
    appearance: {
      type: Object,
      required: true
    }
  },
  computed: {
    uiVideoPropsData () : PropsData {
      let ytId
      if (!this.gallery?.rows['1'].columns['1']) {
        ytId = ''
      } else {
        ytId = this.gallery.rows['1'].columns['1'].publicUrl.match(/embed\/(.*)\?/)
      }
      ytId = ytId && ytId.length === 2 ? ytId[1] : ''

      const headerType = this.headerLayout > 0 ? `h${this.headerLayout}` : 'h2'

      return {
        title: this.header,
        headerTag: headerType,
        description: this.bodytext,
        youtubeId: ytId
      }
    }
  }
})
