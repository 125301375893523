<template functional>
  <div
    :class="[
      'ce-frame',
      `frame-${props.frameClass}`,
      $style[`frame-mob-${props.mobileFrameClass}`],
      `layout-${props.layout}`,
      `space-before-${
        props.spaceBefore.length ? props.spaceBefore : 'default'
      }`,
      `space-after-${
        props.spaceAfter.length ? props.spaceAfter : 'default'
      }`,
      {[$style[props.background]]: props.frameClass !== 'container'},
      {[$style.indentContent]: props.spaceBefore === 'indentContent'},
      {[$style.masthead]: props.layout === 'masthead'},
    ]"
  >
    <div
      v-if="props.frameClass !== 'full-width'"
      :class="[
        'container',
        {[$style[props.background]]: props.frameClass === 'container'},
        {[$style.containerShadow]: props.frameShadow},
        {'container-small': props.spaceBefore === 'indentContent'},
        {[$style.indentWrapper]: props.spaceBefore === 'indentContent'}
      ]"
    >
      <slot />
    </div>
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: 'UiFrame',
  props: {
    frameClass: {
      type: String,
      default: ''
    },
    mobileFrameClass: {
      type: String,
      default: ''
    },
    layout: {
      type: String,
      default: 'default'
    },
    spaceAfter: {
      type: String,
      default: 'default'
    },
    spaceBefore: {
      type: String,
      default: 'default'
    },
    background: {
      type: String,
      default: ''
    },
    frameShadow: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
@import "../../styles/shared";

.indentContent {
  background-color: transparent;

  @include media-query(max sm) {
    padding: 0 2rem;
  }

  @include media-query(max xs) {
    padding: 0 0.7rem;
  }
}

.indentWrapper {
  background-color: $palette-white;
}

.containerShadow {
  box-shadow: $shadow-primary;
}

.yellow {
  background-color: $color-brand-secondary;
}

.white {
  background-color: $palette-white;
}

.dark {
  color: $color-text-secondary;
  background-color: $color-brand-primary;
}

.masthead {
  background-color: transparent;
}

.frame-mob-full-width {
  @include media-query(max sm) {
    width: 100%;
    padding: 0;
  }
}

</style>
