










































































import Vue, { PropType } from 'vue'
import UiRichText from '../../UiRichText/UiRichText.vue'
import {
  BannerData,
  BannerBackground,
  BannerIcon,
  Image,
  BuyProduct,
  BannerAppearance
} from '../UiTextWithImages.types'
import UiIcon from '../../UiIcon'
import UiBuyButton from '../../UiBuyButton'
import UiImage from '../../UiImage'

export default Vue.extend({
  name: 'UiMainBanner',
  components: {
    UiRichText,
    UiIcon,
    UiBuyButton,
    UiImage
  },
  props: {
    bannerData: {
      type: Object as PropType<BannerData>,
      required: true
    },
    background: {
      type: Object as PropType<BannerBackground>,
      default: null
    },
    icon: {
      type: Object as PropType<BannerIcon>,
      default: null
    },
    bannerGallery: {
      type: Array as PropType<Image[]>,
      required: true
    },
    appearance: {
      type: Object as PropType<BannerAppearance>,
      required: true
    },
    buyButton: {
      type: Object as PropType<BuyProduct>,
      required: true
    }
  },
  computed: {
    backgroundImage () {
      return this.background !== null ? this.background?.publicUrl : ''
    },
    isCenter (): boolean {
      return this.bannerData.alignment === 'center'
    },
    isImgLeft (): boolean {
      return this.bannerData.alignment === 'left'
    },
    layoutClass (): string {
      return this.appearance.layout
    },
    isBackgroundAbsolute (): boolean {
      return this.appearance.spaceAfter === 'backgroundAbsolute'
    },
    isImageAdded (): boolean {
      return this.bannerGallery.length > 0
    }
  }
})

