// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Y9z{display:flex;flex-wrap:wrap;padding:5.8rem 0;text-align:center}@media (max-width:47.9375em){._1Y9z{flex-direction:column-reverse}}._1Y9z .nQCJ{margin-bottom:1.6rem}._1Y9z ._2_Sc{max-width:100%}@media (min-width:62em){._1Y9z ._2_Sc{max-width:420px}}._1Y9z ._2_Sc ul{list-style-position:inside;padding-left:0;margin:0;font-size:18px}._1Y9z ._2iOs{padding-bottom:8px}._1Y9z ._3pOY{display:flex;justify-content:center;align-items:center}._1Y9z ._12da{max-width:100%}@media (min-width:36em){._1Y9z ._12da{max-width:70%}}@media (min-width:62em){._1Y9z ._12da{max-width:460px}}._1Y9z .btn{min-width:270px}@media (min-width:62em){._1Y9z .btn{min-width:390px}}._1Q_z{flex-direction:row-reverse}html[dir=rtl] ._1Y9z ._2_Sc ul{padding-right:0}", ""]);
// Exports
exports.locals = {
	"columnBanner": "_1Y9z",
	"header": "nQCJ",
	"content": "_2_Sc",
	"text": "_2iOs",
	"column": "_3pOY",
	"columnImg": "_12da",
	"columnBanner__IMG_LEFT": "_1Q_z"
};
module.exports = exports;
