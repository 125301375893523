/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'minus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.4 12a9.6 9.6 0 1119.2 0 9.6 9.6 0 01-19.2 0zm4.02.9h11.16a.9.9 0 000-1.8H6.42a.9.9 0 100 1.8z" _fill="#373737"/>'
  }
})
