import defu from 'defu'
import api from '~typo3/plugins/api'
import i18n from '~typo3/plugins/i18n'
import domains from '~typo3/plugins/domains'
export default function (context, inject) {
  const runtimeConfig = context.$config && context.$config.typo3
  let moduleOptions = {"baseURL":"http:\u002F\u002F127.0.0.1:3000","api":{"baseURL":"http:\u002F\u002Flocalhost:3000\u002Fapi","endpoints":{"initialData":"\u002F?type=834"}},"layouts":{},"i18n":{"locales":["en"],"defaultLocale":"en","onLocaleChange":() => null,"beforeLocaleChange":() => null},"registerComponents":true,"registerLayouts":true,"debug":false,"domains":[{"name":"robomow.com","baseURL":"https:\u002F\u002Fwww.robomow.com","api":{"baseURL":"https:\u002F\u002Fwww.robomow.com\u002Fheadless"},"i18n":{"locales":["en","de-AT","nl-BE","fr-BE","cs-CZ","da-DK","fi_FI","fr-FR","de-DE","hu_HU","en-IE","he-IL","it-IT","fr-LU","de-LU","nl-NL","nn-NO","pl-PL","ru-RU","es-ES","sv-SE","de-CH","fr-CH","en-GB"],"defaultLocale":"en"}}]}

  if (runtimeConfig) {
    moduleOptions = defu(runtimeConfig, moduleOptions)
  }

  const _options = {
    api: api(context, moduleOptions),
    i18n: i18n(context, moduleOptions),
    domains: domains(context, moduleOptions),
    options: moduleOptions
  }
  inject('typo3', _options)
}
