// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._19mM{display:flex;align-items:flex-start;color:#fff}._19mM svg{margin:4px 5px 0 0}.XPxq{margin:0 10px 0 0;font-size:1.3rem;line-height:1.6rem;color:inherit;position:relative;transition:color .3s ease-in-out}.XPxq:after{content:\"/\";position:absolute;right:-7px;top:50%;transform:translateY(-50%);pointer-events:none}.XPxq:hover{color:#fcd700}.XPxq:hover:after{color:#fff}.LLu0{margin:0;display:inline-block}.LLu0:after{display:none}.LLu0:hover{color:inherit}html[dir=rtl] ._19mM svg{margin:4px 0 0 5px}html[dir=rtl] .XPxq{margin:0 0 0 10px;display:inline-block}html[dir=rtl] .XPxq:after{right:auto;left:-7px}", ""]);
// Exports
exports.locals = {
	"wrapp": "_19mM",
	"link": "XPxq",
	"link__LAST": "LLu0"
};
module.exports = exports;
