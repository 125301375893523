// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dPRI{max-width:620px;margin:0 auto;padding:2.4rem 0 4rem;text-align:center}.dPRI p a:not(.btn){margin:10px;display:inline-block;border:none}.dPRI p a:not(.btn):hover{border:none}.dPRI h2{margin:0 0 .8rem}.dPRI h3{margin:0 0 1.6rem;font-size:2.2rem;font-size:var(--font-size-h4,2.2rem);line-height:2.4rem;line-height:var(--line-height-h4,2.4rem)}.dPRI p{margin:0 0 1.6rem;font-size:1.6rem;font-size:var(--font-size-p2,1.6rem);line-height:2.4rem;line-height:var(--line-height-p2,2.4rem)}", ""]);
// Exports
exports.locals = {
	"content": "dPRI"
};
module.exports = exports;
