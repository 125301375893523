















import Vue from 'vue'
import {
  Testimonial,
  TestimonialModel,
  TestimonialLogo
} from 'robomow-ui/src/components/UiTestimonialsDetails/UiTestimonialsDetails.types'
import getHeaderTag from '../../utils/getHeaderTag'

export default Vue.extend({
  components: {
    UiTestimonialsDetails: () => import(/* webpackChunkName:"UiTestimonialsDetails" */'robomow-ui/src/components/UiTestimonialsDetails'),
    UiTestimonials: () => import(/* webpackChunkName:"UiTestimonials" */'robomow-ui/src/components/UiTestimonials')
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    headerLayout: {
      type: Number,
      required: true
    }
  },
  computed: {
    testimonialDetails () : Testimonial {
      return {
        title: this.data.testimonial.title,
        rating: this.data.testimonial.rating,
        quote: this.data.testimonial.quote,
        content: this.data.testimonial.content,
        published: this.data.testimonial.published
      }
    },
    testimonialModel () : TestimonialModel {
      return this.data.testimonial?.model
        ? ({
          name: this.data.testimonial.model.name,
          publicUrl: this.data.testimonial.model.image?.publicUrl
        })
        : ({})
    },
    testimonialLogo () : TestimonialLogo {
      return {
        publicUrl: this.data.testimonial.logo.publicUrl,
        alt: this.data.testimonial.logo.properties.alternative,
        link: this.data.testimonial.logo.properties.link
      }
    },
    headerTag () : string {
      return getHeaderTag(this.headerLayout)
    }
  }
})
