














import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'
import { ProductHeader, ProductHeaderContent, ProductImage, ProductTableRow, SliderData, ProductSlide, ProductTestimonials } from 'robomow-ui/src/components/UiProductDetails/UiProductDetails.types'
import CeRobomowproductsCards from '../CeRobomowproductsCards'
import { ProductDetailData, Product, ProductAttribute, CompatibleProduct, Image } from './CeRobomowproductsProductdetailview.types'
import buyButton from './../CeBuyButtonScript/buyButton'

export default Vue.extend({
  name: 'CeRobomowproductsProductdetailview',
  components: {
    UiProductDetails: () => import(/* webpackChunkName:"UiProductDetails" */'robomow-ui/src/components/UiProductDetails'),
    CeRobomowproductsCards
  },
  mixins: [buyButton],
  props: {
    subheader: {
      type: String,
      default: ''
    },
    data: {
      type: Object as PropType<ProductDetailData>,
      required: true
    }
  },
  computed: {
    ...mapGetters(['showPrices']),
    headerData (): ProductHeader {
      const product: Product = this.data.product
      if (!product) { return {} as ProductHeader }

      const content: ProductHeaderContent = {
        header: product.name,
        intro: product.shortDescription,
        price: product.price,
        hidePrice: !this.showPrices,
        buyButtonText: product.buttons?.buyButton?.label,
        buyButtonLabel: product.buttons?.buyButton?.label,
        buyButtonEan: product.buttons?.buyButton?.ean,
        buyButtonLang: '.pl',
        locatorButtonText: product.buttons?.locatorButton?.label,
        locatorButtonLabel: product.buttons?.locatorButton?.label,
        locatorButtonLink: product.buttons?.locatorButton?.url,
        isAccessory: product.isAccessory,
        details: product.description
      }

      const image: ProductImage = {
        url: product.image?.publicUrl,
        urlRetina: product.image?.urlRetina,
        urlLqip: product.image?.urlLqip,
        urlGalleryPreview: product.image?.urlGalleryPreview,
        alt: product.image?.properties?.alternative
      }

      const gallery = product?.gallery?.map((galleryImage: Image): ProductImage => {
        return {
          url: galleryImage.publicUrl,
          urlRetina: galleryImage.urlRetina,
          urlLqip: galleryImage.urlLqip,
          urlGalleryPreview: galleryImage.urlGalleryPreview,
          alt: galleryImage.properties?.alternative
        }
      })

      const productTestimonials: ProductTestimonials = {
        header: this.subheader || this.data.i18n?.bestInTestsHeader,
        logos: this.data.logos
      }

      const badge: ProductImage = {
        url: product?.repairabilityIndexLogo?.publicUrl || '',
        urlRetina: product?.repairabilityIndexLogo?.urlRetina || '',
        urlLqip: product?.repairabilityIndexLogo?.urlLqip || '',
        alt: product?.repairabilityIndexLogo?.properties?.alternative || ''
      }

      return {
        content,
        image,
        gallery,
        productTestimonials,
        badge
      }
    },
    tableData (): ProductTableRow[] {
      const attributes: ProductAttribute[] = this.data.product?.attributes
      return attributes?.map((attr): ProductTableRow => {
        return {
          prop: attr.title,
          val: attr.value
        }
      })
    },
    sliderData (): SliderData {
      const compatibleWith: CompatibleProduct[] = this.data.product?.compatibleWith

      const slides: ProductSlide[] = compatibleWith?.map((prod: CompatibleProduct): ProductSlide => {
        const image: ProductImage = {
          url: prod.image?.publicUrl,
          urlRetina: prod.image?.urlRetina,
          urlLqip: prod.image?.urlLqip,
          alt: prod.image?.properties?.alternative
        }

        return {
          title: prod.name,
          link: prod.url,
          image
        }
      })

      return {
        header: this.data.i18n?.compatibleHeader,
        slides
      }
    }
  }
})
