<template>
  <div>
    <template v-if="error.statusCode === 404 && contentElements">
      <CeRenderer :content="contentElements" :frame="true" />
    </template>
    <template v-else>
      <UiError v-if="error" :bodytext="error.message" :code="error.statusCode" />
    </template>
  </div>
</template>
<script>
import UiError from 'robomow-ui/src/components/UiError'

export default {
  name: 'NuxtError',
  components: {
    UiError
  },
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      contentElements: []
    }
  },
  async mounted () {
    if (this.$nuxt.isOffline) {
      this.$router.push('/offline')
    }
    if (
      this.error.statusCode === 404
    ) {
      const code = this.$typo3.i18n.getLocaleCodePath()
      const page404 = await this.$typo3.api.getPage(`${code && '/'}${code}/404`)
      this.contentElements = page404?.data?.content?.colPos0
    }

    if (
      this.error.statusCode === 500 &&
      /^Loading chunk [0-9]+ failed/.test(this.error.message) &&
      window.location.hash !== '#retry'
    ) {
      window.location.hash = '#retry'
      window.location.reload(true)
    }
  }
}
</script>
