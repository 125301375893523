// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/grey-texture.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._2PAE{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");padding:1.3rem 0 2rem;color:#fff;position:relative}._2PAE:before{content:\"\";width:100%;height:1px;background:#fcd700;position:absolute;top:0;left:0}._1T3-{font-size:1.8rem;font-size:var(--font-size-menu,1.8rem);color:#fff}.olyt{margin-top:2.4rem;display:flex}.Hxbu{display:flex;color:#fff;padding-left:0}@media (max-width:35.9375em){._1hWk{width:50%}}.PS44{list-style:none;padding-left:0;margin-top:.5rem}.PS44 a{display:block;color:#f8f8f8;font-size:1.4rem;font-size:var(--font-size-menu,1.4rem);text-decoration:none;transition:color .3s ease-in-out;line-height:2.4rem}.PS44 a:hover{color:#fcd700}._2w_5 p{font-size:1.4rem;font-size:var(--font-size-menu,1.4rem);line-height:1.6rem;margin-top:.5rem;margin-bottom:1.6rem}._2w_5 a:not(._27va){color:#fff;border-bottom:none;transition:color .3s ease-in-out}._2w_5 a:not(._27va):hover{color:#fcd700;border-bottom:none}html[dir=rtl] .Hxbu{padding-left:0}html[dir=rtl] .PS44{padding-right:0;margin-top:.5rem}", ""]);
// Exports
exports.locals = {
	"footer": "_2PAE",
	"header": "_1T3-",
	"content": "olyt",
	"list": "Hxbu",
	"column": "_1hWk",
	"links": "PS44",
	"text": "_2w_5",
	"btn": "_27va"
};
module.exports = exports;
