












import Vue from 'vue'
import UiRichText from '../UiRichText'

export default Vue.extend({
  name: 'UiRobomowAppContent',
  components: {
    UiRichText
  },
  inheritAttrs: false,
  props: {
    bodytext: {
      type: String,
      default: ''
    },
    header: {
      type: String,
      default: ''
    },
    headerTag: {
      type: String,
      default: 'h2'
    }
  }
})
