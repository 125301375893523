/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'quote': {
    width: 46,
    height: 28,
    viewBox: '0 0 46 28',
    data: '<path pid="0" d="M45.667.667h-9.49l-15.02 26.666h13.914L45.667.667zm-20.916 0h-9.49L.334 27.333h13.821L24.751.667z" _fill="#E3E3E3"/>'
  }
})
