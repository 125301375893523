// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1gNZ{background-color:transparent}@media (max-width:35.9375em){._1gNZ{padding:0 2rem}}@media (max-width:22.375em){._1gNZ{padding:0 .7rem}}.dd0X{background-color:#fff}._3n4i{box-shadow:0 .4rem .8rem 0 rgba(0,0,0,.08)}._1G-g{background-color:#fcd700}._1rpd{background-color:#fff}._1kWH{color:#fff;background-color:#373737}._3xQ2{background-color:transparent}@media (max-width:35.9375em){._1uWB{width:100%;padding:0}}", ""]);
// Exports
exports.locals = {
	"indentContent": "_1gNZ",
	"indentWrapper": "dd0X",
	"containerShadow": "_3n4i",
	"yellow": "_1G-g",
	"white": "_1rpd",
	"dark": "_1kWH",
	"masthead": "_3xQ2",
	"frame-mob-full-width": "_1uWB"
};
module.exports = exports;
