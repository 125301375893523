import RowspansElement from './rowspans.types'

const createRowspans = (el: RowspansElement) => {
  const elWidth = el.offsetWidth
  const content = el.textContent || ''
  const words: string[] = content.split(/\s+/)
  let rowWidth = 0

  const measureNode = el.cloneNode(true) as RowspansElement
  measureNode.style.cssText = 'opacity: 0; height: 0; position: absolute;'
  document.body.appendChild(measureNode)

  let htmlString = '<span>'

  words.forEach((word, i) => {
    if (word !== '') {
      word += '&nbsp;'
      measureNode.innerHTML = word
      const wordWidth = measureNode.offsetWidth

      if (rowWidth + wordWidth > elWidth - 25) {
        // create new line
        rowWidth = wordWidth
        htmlString += '</span><br><span>'
        htmlString += word
      } else {
        rowWidth += wordWidth
        htmlString += word
        if (i + 1 === words.length) htmlString += '</span>'
      }
    }
  })

  el.innerHTML = htmlString
  measureNode.remove()
  el.classList.add('rowspans--done')
}
export const name = 'rowspans'
export default {
  inserted (el: RowspansElement) {
    // save reference to createRowspans in the el
    el._createRowspans = createRowspans.bind(null, el)
    window.addEventListener('resize', el._createRowspans)
    document.readyState === 'complete'
      ? createRowspans(el)
      : window.addEventListener('load', el._createRowspans)
  },
  unbind (el: RowspansElement) {
    window.removeEventListener('resize', el._createRowspans)
    window.removeEventListener('load', el._createRowspans)
  }
}
