





















import Vue from 'vue'

export default Vue.extend({
  props: {
    header: {
      type: String,
      required: true
    },
    subheader: {
      type: String,
      required: true
    },
    headerLink: {
      type: [Object, String],
      required: true
    },
    headerLayout: {
      type: Number,
      required: true
    },
    headerPosition: {
      type: String,
      required: true
    }
  },
  computed: {
    headerLevel (): number {
      // by defualt if type is 0, set h1
      return this.headerLayout === 0 ? 1 : this.headerLayout
    },
    headerCss (): string {
      return this.headerPosition ? `ce-header--${this.headerPosition}` : ''
    }
  }
})

