






import Vue from 'vue'
import { mapState } from 'vuex'
import UiBreadcrumbs from 'robomow-ui/src/components/UiBreadcrumbs'
import { RobomowState } from '../../store/index'

export default Vue.extend({
  name: 'CeBreadcrumbs',
  components: {
    UiBreadcrumbs
  },
  props: {

  },
  computed: {
    ...mapState({
      breadcrumbsData: (state: RobomowState) => state.typo3?.page?.page?.breadcrumbs
    })
  }
})
