/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'play': {
    width: 72,
    height: 72,
    viewBox: '0 0 72 72',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 36c0 19.883 16.117 36 36 36s36-16.117 36-36S55.883 0 36 0 0 16.117 0 36zm51.408-1.476L29.76 20.098A1.775 1.775 0 0027 21.571v28.86a1.774 1.774 0 002.755 1.474l21.653-14.426a1.774 1.774 0 000-2.955z" _fill="#FCD700"/>'
  }
})
