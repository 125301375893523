










import Vue from 'vue'
import CwHeader from '../components/CwHeader'
import CwFooter from '../components/CwFooter'
import CwDisclaimerBox from '../components/CwDisclaimerBox'
import CwSparePartsForm from '../components/CwSparePartsForm'
import CwVideoOnQuery from '../components/CwVideoOnQuery'

export default Vue.extend({
  components: {
    CwHeader,
    CwFooter,
    CwDisclaimerBox,
    CwSparePartsForm,
    CwVideoOnQuery
  }
})
