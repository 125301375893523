/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'home': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.4 9.067L8 1.6 1.6 9.067h1.6V14.4h3.2v-4.267h3.2V14.4h3.2V9.067h1.6z" _fill="#fff"/><mask id="a" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M14.4 9.067L8 1.6 1.6 9.067h1.6V14.4h3.2v-4.267h3.2V14.4h3.2V9.067h1.6z" _fill="#fff"/></mask><g mask="url(#a)"><path pid="2" _fill="#fff" d="M1.6 1.6h13v13h-13z"/></g>'
  }
})
