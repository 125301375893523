/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'map-marker': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M14 1.556a9.17 9.17 0 00-9.162 9.123c0 3.632 2.038 6.673 3.531 8.89l.272.404a77.467 77.467 0 004.776 6.223l.59.692.592-.693a77.618 77.618 0 004.776-6.222l.272-.412c1.485-2.217 3.523-5.25 3.523-8.882A9.17 9.17 0 0014 1.556zm0 13.222a5.102 5.102 0 110-10.205 5.102 5.102 0 010 10.205z" _fill="#000"/><path pid="1" d="M14 12.577a2.901 2.901 0 100-5.802 2.901 2.901 0 000 5.802z" _fill="#000"/>'
  }
})
