















import Vue, { PropType } from 'vue'
import UiTextWithImages from 'robomow-ui/src/components/UiTextWithImages'
import {
  BannerGallery,
  BannerBackground,
  BannerIcon,
  BannerData,
  BannerAppearance,
  BuyProduct
} from 'robomow-ui/src/components/UiTextWithImages/UiTextWithImages.types'
import getHeaderTag from '../../utils/getHeaderTag'
import { Gallery } from './CeTextpic.types'

export default Vue.extend({
  components: {
    UiTextWithImages
  },
  props: {
    appearance: {
      type: Object as PropType<BannerAppearance>,
      default: () => {}
    },
    header: {
      type: String,
      required: true
    },
    subheader: {
      type: String,
      required: true
    },
    bodytext: {
      type: String,
      required: true
    },
    headerLink: {
      type: [Object, String],
      required: true
    },
    headerLayout: {
      type: Number,
      required: true
    },
    headerPosition: {
      type: String,
      required: true
    },
    linkLabel: {
      type: String,
      required: true
    },
    gallery: {
      type: Object as PropType<Gallery>,
      default: () => ({} as Gallery)
    },
    background: {
      type: Object as PropType<BannerBackground>,
      default: null
    },
    icon: {
      type: Object as PropType<BannerIcon>,
      default: null
    },
    buyButton: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    content () : BannerData {
      return {
        header: this.header,
        subheader: this.subheader,
        headerTag: getHeaderTag(this.headerLayout),
        link: {
          title: this.linkLabel,
          url: this.headerLink.url,
          target: this.headerLink.target
        },
        richText: this.bodytext,
        textAlignment: this.headerPosition || '',
        alignment: this.gallery.position?.horizontal || ''
      }
    },
    bannerGallery () : BannerGallery {
      const galleryImages = []
      const gallery = this.gallery.rows
      for (const row in gallery) {
        for (const col in gallery[row].columns) {
          galleryImages.push(gallery[row].columns[col])
        }
      }

      return galleryImages
    },
    buyButtonData () : BuyProduct {
      return {
        buyButtonEan: this.buyButton.ean,
        buyButtonName: this.buyButton.name,
        buyButtonText: this.buyButton.label
      }
    }
  }
})
