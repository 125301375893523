





















import Vue from 'vue'
import UiIcon from '../UiIcon'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'UiBuyButton',
  components: {
    UiIcon
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['showBuyButton'])
  }

})
