









import Vue from 'vue'
import UiVideoOverlay from 'robomow-ui/src/components/UiVideoOverlay'
export default Vue.extend({
  components: {
    UiVideoOverlay
  },
  data () {
    return {
      id: null as null | string
    }
  },
  mounted () {
    this.$nextTick(() => {
      const { videoId } = this.$route.query
      if (typeof videoId === 'string') {
        this.showVideo(videoId)
      }
    })
  },
  methods: {
    showVideo (ytId: string): void {
      this.id = ytId
    },
    closeOverlay (): void {
      this.id = null
    }
  }
})
