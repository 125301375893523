




























import Vue, { PropType } from 'vue'
import { Breadcrumb } from './UiBreadcrumbs.types'
import UiIcon from '../UiIcon'

export default Vue.extend({
  name: 'UiBreadcrumbs',
  components: {
    UiIcon
  },
  props: {
    breadcrumbsData: {
      type: Array as PropType<Breadcrumb[]>,
      default: () => []
    }
  },
  computed: {
    breadcrumbsLinks (): Breadcrumb[] {
      return this.breadcrumbsData.slice(0, -1)
    },
    breadcrumbsLast (): Breadcrumb {
      return this.breadcrumbsData.slice(-1)[0]
    }
  }
})
