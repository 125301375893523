




import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'
import { PropsData, Filter } from 'robomow-ui/src/components/UiRobotFinder/UiRobotFinder.interface'
import { Step, Product, I18n } from './CeTxRobomowproductsProductfilters.types'

export default Vue.extend({
  name: 'CeTxRobomowproductsProductfilters',
  components: {
    UiRobotFinder: () => import(/* webpackChunkName:"UiRobotFinder" */'robomow-ui/src/components/UiRobotFinder')
  },
  props: {
    steps: {
      type: Array as PropType<Step[]>,
      default: () => []
    },
    products: {
      type: Array as PropType<Product[]>,
      default: () => []
    },
    i18n: {
      type: Object as PropType<I18n>,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['showPrices']),
    propsData (): PropsData {
      // Filters data mapping
      const filters = this.steps.map((step: Step): Filter => {
        const content = step.content
        const options = content?.filters.map((option) => {
          const optionContent = option.content
          return {
            imageUrl: optionContent?.image?.publicUrl,
            label: optionContent?.label,
            value: optionContent?.value,
            comparison: optionContent?.comparison,
            castToInt: optionContent?.castToInt
          }
        })
        return {
          label: content?.label,
          attribute: content?.filters?.[0]?.content?.attribute,
          options
        }
      })

      // Products data mapping
      const apiProducts = this.products as Product[]
      const products = apiProducts.map((card) => {
        const icons = card.icons.map((icon) => {
          return {
            src: icon.icon?.publicUrl,
            title: icon.caption,
            alt: icon.icon?.properties?.alternative
          }
        })

        const details = card.attributes.map((attribute) => {
          return {
            property: attribute.name,
            value: attribute.value.toString()
          }
        })

        const productData = {
          header: card.name,
          price: card.price,
          hidePrice: !this.showPrices,
          description: card.description,
          buyButtonText: card.buttons?.buyButton?.label,
          buyButtonLabel: card.buttons?.buyButton?.label,
          buyButtonEan: card.buttons?.buyButton?.ean,
          buyButtonLang: '.pl',
          buyButtonName: card.buttons?.buyButton?.name,
          locatorButtonText: card.buttons?.locatorButton?.label,
          locatorButtonLabel: card.buttons?.locatorButton?.label,
          locatorButtonLink: card.buttons?.locatorButton?.url,
          linkText: card.buttons?.detailButton?.label,
          linkLabel: card.buttons?.detailButton?.label,
          link: card.buttons?.detailButton?.url,
          isAccessory: card.isAccessory,
          icons,
          details,
          filterAttributes: card.filterAttributes
        }

        const productImage = {
          url: card.image?.publicUrl,
          urlRetina: card.image?.urlRetina,
          urlLqip: card.image?.urlLqip,
          alt: card.image?.properties?.alternative
        }

        return {
          productData,
          productImage
        }
      })

      // Translations mapping
      const i18n = this.i18n as I18n

      return {
        selectOptionsText: i18n?.yourFiltersHeader,
        noProductsFoundText: i18n?.noMatchingProducts,
        selectBtnText: i18n?.selectButton,
        selectedBtnText: i18n?.selectedButton,
        yourRobomowsText: i18n.yourRobomowHeader,
        filters,
        products
      }
    }
  }
})
