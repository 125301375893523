/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'facebook': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M20.536 28v-9.288h3.11l.466-3.61h-3.576v-2.305c0-1.045.29-1.758 1.79-1.758h1.911v-3.23a25.586 25.586 0 00-2.786-.142c-2.756 0-4.643 1.682-4.643 4.773v2.661h-3.117v3.611h3.117V28H5.333A1.333 1.333 0 014 26.667V5.333C4 4.597 4.597 4 5.333 4h21.334C27.403 4 28 4.597 28 5.333v21.334c0 .736-.597 1.333-1.333 1.333h-6.131z" _fill="#F8F8F8"/>'
  }
})
