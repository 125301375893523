/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'euro': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm8.924-5.5c1.06 0 1.842.19 2.576.604l-.701 1.415c-.522-.35-1.125-.525-1.924-.525-1.696 0-3 .795-3.49 2.21h4.745l-.489 1.112H7.158a4.7 4.7 0 00-.017 1.177h4.027l-.489 1.112H7.304c.375 1.51 1.778 2.4 3.424 2.4.767 0 1.712-.222 2.609-.905v1.732c-.978.493-1.973.668-2.658.668-1.353 0-2.755-.382-3.733-1.303a4.435 4.435 0 01-1.337-2.591H4.5l.49-1.113h.488a4.699 4.699 0 01.017-1.177H4.5l.49-1.112h.668a5.07 5.07 0 011.337-2.321C8.266 4.659 9.865 4.5 10.924 4.5z" _fill="#000"/>'
  }
})
