// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2nxm{padding:3.2rem 1rem 5.6rem;text-align:center}._39_P{font-size:40vw;line-height:40vw;font-weight:700;font-family:\"futura-pt\",sans-serif;color:#d0d0d0}@media (min-width:36em){._39_P{font-size:23rem;line-height:23rem}}.N2uD{margin-bottom:-20vw;position:relative;top:-20vw}.N2uD h1,.N2uD h2,.N2uD h3,.N2uD h4{margin:3.2rem auto 1.6rem;font-size:3.8rem;font-size:var(--font-size-mob-h2,3.8rem);line-height:4rem;line-height:var(--line-height-mob-h2,4rem);font-weight:700;text-transform:uppercase}.N2uD p{margin:3.2rem auto 1.6rem;font-size:2.2rem;font-size:var(--font-size-h5,2.2rem);line-height:2.4rem;line-height:var(--line-height-h5,2.4rem)}.N2uD .btn{margin:3.2rem auto 0}@media (min-width:36em){.N2uD{margin-bottom:-12rem;top:-12rem}}", ""]);
// Exports
exports.locals = {
	"wrapp": "_2nxm",
	"code": "_39_P",
	"content": "N2uD"
};
module.exports = exports;
