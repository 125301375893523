// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "ul{margin:.8rem 0}._1sJR a:not(.btn){border-bottom:2px solid #fcd700;transition:border .3s ease-in-out}._1sJR a:not(.btn):hover{border-bottom:2px solid #373737}", ""]);
// Exports
exports.locals = {
	"content": "_1sJR"
};
module.exports = exports;
