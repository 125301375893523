/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'list': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M33.386 14.409l4 3.999a1.405 1.405 0 01.007 1.986l-2.224 2.224-5.986-5.986 2.224-2.224a1.403 1.403 0 011.98 0zM16.032 34.053l1.288-5.559 10.697-10.71 5.993 5.978L23.272 34.46l-5.592 1.288c-.097.01-.195.01-.292 0a1.395 1.395 0 01-1.356-1.694z" _fill="#373737"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M6.333 5h23.334A2.333 2.333 0 0132 7.333V11.2l-5.833 5.834V15.5H14.5v2.333h10.866l-2.333 2.334H14.5V22.5h6.2l-2.334 2.333H14.5v2.334h1.533l-.676.675L14.143 33h-7.81A2.333 2.333 0 014 30.667V7.333A2.333 2.333 0 016.333 5zm5.834 22.167H9.833v-2.334h2.334v2.334zm0-4.667H9.833v-2.333h2.334V22.5zm0-4.667H9.833V15.5h2.334v2.333zm0-4.666H9.833v-2.334h2.334v2.334zm14 0H14.5v-2.334h11.667v2.334z" _fill="#373737"/>'
  }
})
