// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1xmJ{display:flex;flex-wrap:wrap}._1xmJ a{margin-top:1rem;margin-right:.9rem}._1xmJ a svg{fill:#fff;height:31px}._1xmJ a:hover svg{fill:#fcd700}html[dir=rtl] ._1xmJ a{margin-right:0;margin-left:.9rem}", ""]);
// Exports
exports.locals = {
	"icons": "_1xmJ"
};
module.exports = exports;
