/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'instagram': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 4.85c3.631 0 4.061.014 5.495.08 1.326.06 2.046.282 2.526.468a4.213 4.213 0 011.563 1.017c.476.476.771.93 1.018 1.564.186.48.408 1.2.468 2.525.066 1.434.08 1.865.08 5.496 0 3.631-.014 4.061-.08 5.495-.06 1.326-.282 2.047-.468 2.526a4.213 4.213 0 01-1.018 1.564c-.475.476-.928.77-1.563 1.017-.48.186-1.2.408-2.526.468-1.433.066-1.864.08-5.495.08-3.632 0-4.062-.014-5.496-.08-1.326-.06-2.046-.282-2.525-.468a4.213 4.213 0 01-1.564-1.017 4.214 4.214 0 01-1.017-1.564c-.186-.48-.408-1.2-.468-2.526-.066-1.434-.08-1.864-.08-5.495 0-3.631.014-4.062.08-5.496.06-1.326.282-2.046.468-2.525a4.213 4.213 0 011.017-1.564c.476-.476.93-.77 1.564-1.017.48-.186 1.2-.408 2.525-.468 1.434-.066 1.864-.08 5.496-.08zm0-2.45c-3.694 0-4.157.016-5.607.082-1.448.066-2.437.296-3.302.632a6.666 6.666 0 00-2.408 1.569 6.666 6.666 0 00-1.57 2.408c-.335.865-.565 1.854-.631 3.302C2.415 11.843 2.4 12.306 2.4 16s.016 4.157.082 5.607c.066 1.448.296 2.436.632 3.302a6.668 6.668 0 001.569 2.408 6.665 6.665 0 002.408 1.569c.865.336 1.854.566 3.302.632 1.45.066 1.913.082 5.607.082 3.693 0 4.157-.016 5.607-.082 1.448-.066 2.436-.296 3.301-.632a6.665 6.665 0 002.41-1.569 6.667 6.667 0 001.568-2.409c.336-.865.566-1.853.632-3.3.066-1.451.082-1.915.082-5.608 0-3.694-.016-4.157-.082-5.607-.066-1.448-.296-2.437-.632-3.302a6.667 6.667 0 00-1.569-2.408 6.666 6.666 0 00-2.409-1.57c-.865-.335-1.853-.565-3.3-.631C20.156 2.416 19.692 2.4 16 2.4zm0 6.616a6.984 6.984 0 100 13.968 6.984 6.984 0 000-13.968zm0 11.517a4.533 4.533 0 110-9.067 4.533 4.533 0 010 9.067zM24.892 8.74a1.632 1.632 0 11-3.264 0 1.632 1.632 0 013.264 0z" _fill="#F8F8F8"/>'
  }
})
