





























































import Vue, { PropType } from 'vue'
import UiLogo from '../UiLogo/UiLogo.vue'
import UiSocialIcons from '../UiSocialIcons/UiSocialIcons.vue'
import UiRichText from '../UiRichText/UiRichText.vue'
import { FooterItems, ContactData, I18n } from './UiFooter.types'
import { SocialIcons } from '../UiSocialIcons/UiSocialIcons.types'

export default Vue.extend({
  name: 'UiFooter',
  components: {
    UiLogo,
    UiSocialIcons,
    UiRichText
  },
  props: {
    footerItems: {
      type: Array as PropType<FooterItems>,
      required: true
    },
    contactData: {
      type: Object as PropType<ContactData>,
      required: true
    },
    logoUrl: {
      type: String,
      required: true
    },
    socialIcons: {
      type: Array as PropType<SocialIcons>,
      required: true
    },
    i18n: {
      type: Object as PropType<I18n>,
      default: () => ({})
    }
  },
  computed: {
    i18nVerifed (): I18n {
      const defaultTranslations: I18n = {
        socialLabel: 'Follow Us'
      }

      return Object.assign(defaultTranslations, this.i18n)
    }
  }
})
