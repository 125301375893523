








import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'
import { SeriesList, I18n, ProductItem, ProductContent, ProductImage, Details } from 'robomow-ui/src/components/UiSeriesList/UiSeriesList.types'
import { SeriesInfoData, BadgeIcon } from 'robomow-ui/src/components/UiSeriesInfo/UiSeriesInfo.types'
import { ProductSeriesData, Series, Icon, ProductModel, ProductAttribute } from './CeRobomowproductsSerieslist.types'
import buyButton from './../CeBuyButtonScript/buyButton'

export default Vue.extend({
  name: 'CeRobomowproductsSerieslist',
  components: {
    UiSeriesList: () => import(/* webpackChunkName:"UiSeriesList" */'robomow-ui/src/components/UiSeriesList')
  },
  mixins: [buyButton],
  props: {
    data: {
      type: Object as PropType<ProductSeriesData>,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['showPrices']),
    seriesListData () : SeriesList[] {
      const data = this.data as ProductSeriesData
      return data.series?.map((series: Series): SeriesList => {
        const badges = series.icons?.map((icon: Icon): BadgeIcon => {
          return {
            src: icon.icon?.publicUrl,
            title: icon.caption,
            alt: icon.icon?.properties?.alternative
          }
        })

        const headerData: SeriesInfoData = {
          header: series.name,
          buttonText: series.button?.label,
          buttonLink: series.button?.url,
          badges,
          smallButton: true
        }

        const products = series.models?.map((model: ProductModel): ProductItem => {
          const details = model.attributes.map((attribute : ProductAttribute) : Details => {
            return {
              property: attribute.name,
              value: attribute.value.toString()
            }
          })

          const content: ProductContent = {
            header: model.name,
            price: model.price,
            hidePrice: !this.showPrices,
            ean: model.ean,
            buyButtonText: model.buttons?.buyButton?.label,
            buyButtonLabel: model.buttons?.buyButton?.label,
            buyButtonEan: model.buttons?.buyButton?.ean,
            buyButtonLang: '.pl',
            buyButtonName: model.buttons?.buyButton?.name,
            locatorButtonText: model.buttons?.locatorButton?.label,
            locatorButtonLabel: model.buttons?.locatorButton?.label,
            locatorButtonLink: model.buttons?.locatorButton?.url,
            linkText: model.buttons?.detailButton?.label,
            linkLabel: model.buttons?.detailButton?.label,
            link: model.buttons?.detailButton?.url,
            isAccessory: model.isAccessory,
            details
          }

          const image: ProductImage = {
            url: model.image?.publicUrl,
            urlRetina: model.image?.urlRetina,
            urlLqip: model.image?.urlLqip,
            alt: model.image?.properties?.alternative
          }

          return {
            content,
            image
          }
        })

        return {
          headerData,
          products
        }
      })
    },
    i18n (): I18n {
      const data = this.data as ProductSeriesData
      return {
        addToComparison: data.i18n?.addToComparison,
        manageRobots: data.i18n?.manageRobots,
        addButton: data.i18n?.buttons?.add,
        removeButton: data.i18n?.buttons?.remove,
        compareButton: data.i18n?.buttons?.compare
      }
    },
    comparisonPage (): string {
      const data = this.data as ProductSeriesData
      return data?.comparisonPageUri
    }
  }
})
