// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".m0O5{display:inline-block;position:relative;width:30px;height:30px}.m0O5 div{box-sizing:border-box;display:block;position:absolute;width:24px;height:24px;margin:3px;border-radius:50%;-webkit-animation:m0O5 1.2s cubic-bezier(.5,0,.5,1) infinite;animation:m0O5 1.2s cubic-bezier(.5,0,.5,1) infinite;border:3px solid transparent;border-top-color:#373737}.m0O5 div:first-child{-webkit-animation-delay:-.45s;animation-delay:-.45s}.m0O5 div:nth-child(2){-webkit-animation-delay:-.3s;animation-delay:-.3s}.m0O5 div:nth-child(3){-webkit-animation-delay:-.15s;animation-delay:-.15s}@-webkit-keyframes m0O5{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}@keyframes m0O5{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}", ""]);
// Exports
exports.locals = {
	"ld": "m0O5"
};
module.exports = exports;
