


































































































































import Vue, { PropType } from 'vue'
import UiLogo from '../UiLogo/UiLogo.vue'
import UiIcon from '../UiIcon'
import UiHeaderHamburger from './UiHeaderHamburger'
import UiHeaderLangSwitcher from './UiHeaderLangSwitcher'
import UiHeaderSearch from './UiHeaderSearch'
import type { HeaderData, Languages, MenuItems, I18n, SearchDocument } from './UiHeader.types'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { EventBus } from '../../plugin'

export default Vue.extend({
  name: 'UiHeader',
  components: {
    UiLogo,
    UiIcon,
    UiHeaderHamburger,
    UiHeaderLangSwitcher,
    UiHeaderSearch
  },
  props: {
    languages: {
      type: Object as PropType<Languages>,
      required: true
    },
    logoUrl: {
      type: String,
      default: '/'
    },
    menuItems: {
      type: Array as PropType<MenuItems>,
      required: true
    },
    i18n: {
      type: Object as PropType<I18n>,
      default: () => ({})
    },
    searchDocuments: {
      type: Array as PropType<SearchDocument[]>,
      required: true
    }
  },
  data () {
    return {
      headerMode: '',
      openedNavItemIndex: -1,
      currentDropdownIndex: -1
    } as HeaderData
  },
  computed: {
    i18nVerifed (): I18n {
      const defaultTranslations: I18n = {
        languagesHeader: 'Select your location',
        languagesLabel: 'Choose region',
        burgerLabel: 'Menu',
        searchBtn: 'Search',
        searchLabel: 'Search',
        searchPlaceholder: 'Search our site...',
        products: 'Products',
        news: 'News',
        pages: 'Site',
        results: 'results'
      }

      return Object.assign(defaultTranslations, this.i18n)
    },
    isDesktopMenu (): boolean {
      return ['xl', 'xxl'].includes(this.$mq)
    }
  },
  watch: {
    $route () {
      this.resetMode()
    }
  },
  methods: {
    setMode (mode: HeaderData['headerMode'], lockElement: HTMLElement): void {
      clearAllBodyScrollLocks()
      if (lockElement && mode !== '') {
        disableBodyScroll(lockElement)
      }
      this.headerMode = mode
    },
    resetMode (): void {
      this.headerMode = ''
      this.openedNavItemIndex = -1
      this.currentDropdownIndex = -1
      clearAllBodyScrollLocks()
    },
    dropdownClickHandler (index: number): void {
      (document.activeElement as HTMLElement)?.blur()
      if (this.isDesktopMenu) {
        this.headerMode = 'MENU'
        this.currentDropdownIndex = this.currentDropdownIndex === index ? -1 : index
      } else {
        // Toggle sub-items list on mobile devices
        this.openedNavItemIndex = this.openedNavItemIndex === index ? -1 : index
      }
    },
    hiddenFormHandler ($event: Event): any {
      EventBus.$emit('submit-spare-parts', $event)
    },
    dropdownMouseLeaveHandler (): void {
      this.isDesktopMenu && this.resetMode()
    }
  }
})
