












import { Vue, Component, Prop } from 'vue-property-decorator'
import { PropsData as UiStoreLocatorPropsData } from 'robomow-ui/src/components/UiStoreLocator/UiStoreLocator.types'
import UiSpinner from 'robomow-ui/src/components/UiSpinner'
import { PropData } from './CeMtdstorelocatorStorelocations.types'

@Component({
  components: {
    UiStoreLocator: () => import(/* webpackChunkName:"UiStoreLocator" */'robomow-ui/src/components/UiStoreLocator'),
    UiSpinner
  }
})
export default class CeMtdstorelocatorStorelocations extends Vue {
  @Prop() data!: PropData

  isComponentMounted = false

  get uiStoreLocatorPropsData (): UiStoreLocatorPropsData {
    const locations = this.data.points.map((point) => {
      const badges = point.badges.map(badge => `<img class="badge" src="${badge.publicUrl}" alt="${badge.properties.alternative}">`).join(' ')
      const labels = this.data.i18n?.ui?.labels
      let richTextInner = ''
      if (point.address2) {
        richTextInner += `<span>${point.address2}</span><br/>`
      }
      if (point.city) {
        richTextInner += `${point.zip}, ${point.city}<br/>`
      }
      if (point.phone && labels?.phone) {
        richTextInner += `<strong>${labels?.phone}:</strong> ${point.phone}<br/>`
      }
      if (point.fax && labels?.fax) {
        richTextInner += `<strong>${labels?.fax}:</strong> ${point.fax}<br/>`
      }
      if (point.email && labels?.email) {
        richTextInner += `<strong>${labels?.email}:</strong> <a href="mailto:${point.email}">${point.email}</a><br/>`
      }
      if (point.www && labels?.www) {
        richTextInner += `<strong>${labels?.www}:</strong> <a href="${point.www}" target="_blank" rel="noopener noreferrer">${point.www}</a>`
      }
      return {
        title: point.title,
        lat: point.lat,
        lng: point.lng,
        type: [...point.types],
        richText: `${badges ? `<div class="badgesContainer">${badges}</div>` : ''}<p>${richTextInner}</p>`
      }
    })

    return {
      locations,
      configuration: {
        startLat: this.data.conf.lat,
        startLng: this.data.conf.lng,
        searchCountrySuffix: this.data.conf.localizeCountry,
        apiUrl: this.data.conf.apiUrl
      },
      filters: {
        isDealerEnabled: this.data.filters.dealer.available === 1,
        isServiceEnabled: this.data.filters.service.available === 1,
        isDealerPowerEnabled: this.data.filters.dealer_power.available === 1,
        isCertifiedDealerEnabled: this.data.filters.certified_dealer.available === 1
      },
      i18n: {
        showMore: this.data.i18n.ui.btn.showMore,
        nothingToShow: this.data.i18n.ui.placeholder.nothingToShow,
        searching: this.data.i18n.ui.placeholder.searching,
        placeholder: this.data.i18n.ui.placeholder.search,
        localiseMe: this.data.i18n.ui.btn.locate,
        dealer: this.data.filters.dealer.label,
        service: this.data.filters.service.label,
        dealer_power: this.data.filters.dealer_power.label,
        certified_dealer: this.data.filters.certified_dealer.label,
        resetView: this.data.i18n.ui.btn.resetView,
        back: this.data.i18n.ui.btn.back,
        goToTheNearestLocation: this.data.i18n.ui.btn.goToNearestLocation,
        showOnMap: this.data.i18n.ui.btn.showOnMap,
        search: this.data.i18n.ui.btn.search
      }
    }
  }

  mounted () {
    this.isComponentMounted = true
  }
}
