/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'warning': {
    width: 4,
    height: 22,
    viewBox: '0 0 4 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2 .4A1.4 1.4 0 00.6 1.8v12a1.4 1.4 0 102.8 0v-12A1.4 1.4 0 002 .4zM.2 19.8a1.8 1.8 0 103.6 0 1.8 1.8 0 00-3.6 0z" _fill="#fff"/>'
  }
})
