/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'plus': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4 20c0 8.837 7.163 16 16 16s16-7.163 16-16A16 16 0 0020 4C11.163 4 4 11.163 4 20zm17.5-1.5h6.09a1.5 1.5 0 110 3H21.5v6.09a1.5 1.5 0 11-3 0V21.5h-6.09a1.5 1.5 0 110-3h6.09v-6.09a1.5 1.5 0 013 0v6.09z" _fill="#000"/>'
  }
})
