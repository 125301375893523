











import Vue from 'vue'
import { mapState } from 'vuex'
import UiHeader from 'robomow-ui/src/components/UiHeader'
import { Languages, I18n, SearchDocument } from 'robomow-ui/src/components/UiHeader/UiHeader.types'
import { RobomowState } from '../../store/index'
import { TYPO3 } from '../../types/nuxt-typo3'
import getCeFromPage from '../../utils/getCeFromPage'

export default Vue.extend({
  name: 'CwHeader',
  components: {
    UiHeader
  },
  data () {
    return {
      mainPageUrl: '/',
      searchDocuments: [] as SearchDocument[]
    }
  },
  computed: {
    ...mapState({
      mainMenu: (state: RobomowState) => state.typo3?.initial?.mainMenu,
      sitesMenu: (state: RobomowState) => state.typo3?.initial?.sitesMenu,
      translations: (state: RobomowState) => state.typo3?.initial?.configuration?.translations,
      searchUrl: (state: RobomowState) => state.typo3?.initial?.configuration?.searchPage?.url
    }),
    mainMenuTranslations (): I18n {
      return {
        languagesHeader: this.translations?.general?.selectLocation,
        languagesLabel: this.translations?.aria?.region,
        burgerLabel: this.translations?.aria?.menu,
        searchBtn: this.translations?.search?.label,
        searchLabel: this.translations?.aria?.search,
        searchPlaceholder: this.translations?.search?.inputPlaceholder,
        products: this.translations?.search?.categories?.products,
        news: this.translations?.search?.categories?.news,
        pages: this.translations?.search?.categories?.pages,
        results: this.translations?.search?.results
      }
    },
    languages () : Languages {
      const current = this.sitesMenu?.find(lang => lang.active === 1) || this.sitesMenu?.[0]

      return {
        current,
        all: this.sitesMenu
      }
    }
  },
  watch: {
    mainMenu: {
      handler () {
        const locale = this.$nuxt.$typo3.i18n.getLocaleCodePath()
        this.mainPageUrl = `/${locale && locale}`
      },
      immediate: true
    }
  },
  methods: {
    async fetchSearchData (): Promise<void> {
      try {
        const searchPage = await (this.$typo3 as TYPO3.Plugin).api.$http.$get(this.searchUrl)
        // get specific Ce data from Typo3 page
        const searchData = getCeFromPage(searchPage, 'solr_pi_results')
        if (searchData[0]) {
          this.searchDocuments = this.getSearchDocs(searchData[0])
        }
      } catch (e) {
        console.log(e)
        setTimeout(() => this.fetchSearchData(), 7000)
      }
    },
    // Returns documents from solr_pi_results element
    getSearchDocs (searchCe: TYPO3.Types.ContentElement): SearchDocument[] {
      return searchCe.content?.data?.documents?.list?.results
    }
  }
})
