






export default {
  name: 'UiSpinner'
}
