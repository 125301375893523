var render = function (_h,_vm) {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5;
var _c=_vm._c;return _c('div',{class:[
    'ce-frame',
    ("frame-" + (_vm.props.frameClass)),
    _vm.$style[("frame-mob-" + (_vm.props.mobileFrameClass))],
    ("layout-" + (_vm.props.layout)),
    ("space-before-" + (_vm.props.spaceBefore.length ? _vm.props.spaceBefore : 'default')),
    ("space-after-" + (_vm.props.spaceAfter.length ? _vm.props.spaceAfter : 'default')),
    ( _obj = {}, _obj[_vm.$style[_vm.props.background]] = _vm.props.frameClass !== 'container', _obj ),
    ( _obj$1 = {}, _obj$1[_vm.$style.indentContent] = _vm.props.spaceBefore === 'indentContent', _obj$1 ),
    ( _obj$2 = {}, _obj$2[_vm.$style.masthead] = _vm.props.layout === 'masthead', _obj$2 ) ]},[(_vm.props.frameClass !== 'full-width')?_c('div',{class:[
      'container',
      ( _obj$3 = {}, _obj$3[_vm.$style[_vm.props.background]] = _vm.props.frameClass === 'container', _obj$3 ),
      ( _obj$4 = {}, _obj$4[_vm.$style.containerShadow] = _vm.props.frameShadow, _obj$4 ),
      {'container-small': _vm.props.spaceBefore === 'indentContent'},
      ( _obj$5 = {}, _obj$5[_vm.$style.indentWrapper] = _vm.props.spaceBefore === 'indentContent', _obj$5 )
    ]},[_vm._t("default")],2):_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }