
import CeFrame from '~typo3/components/content/CeFrame'
export default {
  extends: CeFrame,
  props: {
    frameClass: {
      type: String,
      default: ''
    },
    mobileFrameClass: {
      type: String,
      default: ''
    },
    layout: {
      type: String,
      default: 'default'
    },
    spaceAfter: {
      type: String,
      default: 'default'
    },
    spaceBefore: {
      type: String,
      default: 'default'
    },
    background: {
      type: String,
      default: ''
    },
    frameShadow: {
      type: Boolean,
      default: false
    }
  },
  render (createElement, ctx) {
    return createElement(
      'ui-frame',
      {
        props: ctx.props
      },
      ctx.children
    )
  }
}
