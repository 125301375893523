















import Vue, { PropType } from 'vue'
import UiRobomowAppContent from 'robomow-ui/src/components/UiRobomowAppContent'
import UiError from 'robomow-ui/src/components/UiError'
import getHeaderTag from '../../utils/getHeaderTag'
import { Appearance } from './CeText.types'
import CeText from '~typo3/components/content/elements/CeText'

export default Vue.extend({
  components: {
    UiRobomowAppContent,
    UiError
  },
  extends: CeText,
  props: {
    appearance: {
      type: Object as PropType<Appearance>,
      required: true
    },
    headerLayout: {
      type: Number,
      default: 0
    }
  },
  computed: {
    headerTag (): string {
      return getHeaderTag(this.headerLayout)
    }
  }
})
