





















import Vue from 'vue'

export default Vue.extend({
  name: 'UiHeaderHamburger',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    btnLabel: {
      type: String,
      default: 'Menu'
    }
  }
})
