

























































import Vue, { PropType } from 'vue'
import UiRichText from '../../UiRichText/UiRichText.vue'
import UiImage from '../../UiImage'
import { BannerData, Image, BannerAppearance } from '../UiTextWithImages.types'

export default Vue.extend({
  name: 'UiColumnBanner',
  components: {
    UiRichText,
    UiImage
  },
  inheritAttrs: false,
  props: {
    bannerData: {
      type: Object as PropType<BannerData>,
      required: true
    },
    bannerGallery: {
      type: Array as PropType<Image[]>,
      required: true
    },
    appearance: {
      type: Object as PropType<BannerAppearance>,
      required: true
    }
  },
  computed: {
    toLeft (): boolean {
      return this.bannerData.alignment === 'left'
    },
    textAlignment (): string | boolean {
      return this.bannerData.textAlignment || false
    },
    headerTag (): string {
      return this.bannerData.headerTag || 'h2'
    },
    isTopHeader (): boolean {
      return this.appearance.layout === 'columns-top-header'
    }
  }
})

