



























import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { EventBus } from 'robomow-ui'
import { RobomowState } from '../../store/index'

export default Vue.extend({
  name: 'CwSparePartsForm',
  computed: {
    ...mapState({
      login: (state: RobomowState) => state.typo3?.initial?.configuration?.agroParts?.login || '',
      pass: (state: RobomowState) => state.typo3?.initial?.configuration?.agroParts?.pass || '',
      action: (state: RobomowState) => state.typo3?.initial?.configuration?.agroParts?.url || ''
    }),
    ...mapGetters(['langCode'])
  },
  mounted () {
    EventBus.$on('submit-spare-parts', this.formHandler)
  },
  beforeDestroy () {
    EventBus.$off('submit-spare-parts', this.formHandler)
  },
  methods: {
    formHandler (e: Event) {
      e.preventDefault();
      (this.$refs.form as HTMLFormElement)?.submit()
    }
  }
})
