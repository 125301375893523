import VueMq from 'vue-mq'
import VueObserveVisibility from 'vue-observe-visibility'

let EventBus

const UiPlugin = {
  install: (Vue) => {
    /**
     * Import all needed icons
     */
    require('robomow-ui/src/components/UiIcon/helpers/plugin')
    /**
     * Add vue-mq pugin
     */
    Vue.use(VueMq, {
      breakpoints: {
        xxs: 359,
        xs: 576,
        sm: 768,
        md: 992,
        lg: 1190,
        xl: 1366,
        xxl: Infinity
      },
      defaultBreakpoint: 'xxl'
    })
    /*
    vue-observe-visibility global directive
    */
    Vue.use(VueObserveVisibility)
    /*
    Auto installation of all components in vue files suffixed by ".global"
    */
    const requireComponent = require.context(
      '../components',
      true,
      /[\w]+\.global.vue$/
    )
    requireComponent.keys().forEach(fileName => {
      const componentConfig = requireComponent(fileName).default
      Vue.component(componentConfig.name, componentConfig)
    })

    /*
    Auto installation of all directives placed in src/directives and suffixed by .global;
    Auto installed directive must provide 2 exports: "name" and "config",
    where name is directive name and conig is configuration object of the directive.
    */
    try {
      const requireDirective = require.context('../directives', true, /\.global.(js|jsx|ts|tsx)$/)
      requireDirective.keys().forEach(fileName => {
        const directiveExports = requireDirective(fileName)
        const name = directiveExports.name
        const config = directiveExports.default
        Vue.directive(name, config)
      })
    // eslint-disable-next-line no-empty
    } catch (err) {}

    // Create EventBus
    EventBus = new Vue()
  }
}

export default UiPlugin
export { EventBus }
