








import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'
import { ProductCard } from 'robomow-ui/src/components/UiProductCards/UiProductCards.types'
import { ProductBoxData, ProductBoxImage, BadgeIcon, Details } from 'robomow-ui/src/components/UiProductBox/UiProductBox.types'
import getHeaderTag from '../../utils/getHeaderTag'
import { ProductCardData, Icon, ProductAttribute } from './CeRobomowproductsCards.types'
import buyButton from './../CeBuyButtonScript/buyButton'

export default Vue.extend({
  name: 'CeRobomowproductsCards',
  components: {
    UiProductCards: () => import(/* webpackChunkName:"UiProductCards" */'robomow-ui/src/components/UiProductCards')
  },
  mixins: [buyButton],
  props: {
    header: {
      type: String,
      default: ''
    },
    headerLayout: {
      type: Number,
      default: 0
    },
    data: {
      type: Array as PropType<ProductCardData[]>,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['showPrices']),
    headerTag () : string {
      return getHeaderTag(this.headerLayout)
    },
    productCards () : ProductCard[] {
      return this.data.map((card : ProductCardData) : ProductCard => {
        const icons = card.icons?.map((icon : Icon) : BadgeIcon => {
          return {
            src: icon.icon?.publicUrl,
            title: icon.caption,
            alt: icon.icon?.properties?.alternative
          }
        })

        const details = card?.attributes?.map((attribute : ProductAttribute) : Details => {
          return {
            property: attribute.name,
            value: attribute.value.toString()
          }
        })

        const productData : ProductBoxData = {
          header: card.name,
          series: card.series,
          seriesHeaderTag: card.seriesHeaderTag,
          seriesSubheader: card.seriesSubheader,
          price: card.price,
          hidePrice: !this.showPrices,
          description: card.description,
          buyButtonText: card.buttons?.buyButton?.label,
          buyButtonLabel: card.buttons?.buyButton?.label,
          buyButtonEan: card.buttons?.buyButton?.ean,
          buyButtonLang: '.pl',
          buyButtonName: card.buttons?.buyButton?.name,
          locatorButtonText: card.buttons?.locatorButton?.label,
          locatorButtonLabel: card.buttons?.locatorButton?.label,
          locatorButtonLink: card.buttons?.locatorButton?.url,
          seriesButtonText: card.buttons?.seriesButton?.label,
          seriesButtonLabel: card.buttons?.seriesButton?.label,
          seriesButtonLink: card.buttons?.seriesButton?.url,
          linkText: card.buttons?.detailButton?.label,
          linkLabel: card.buttons?.detailButton?.label,
          link: card.buttons?.detailButton?.url || card.buttons?.seriesButton?.url,
          isAccessory: card.isAccessory,
          icons,
          details
        }

        const productImage : ProductBoxImage = {
          url: card.image?.publicUrl,
          urlRetina: card.image?.urlRetina,
          urlLqip: card.image?.urlLqip,
          alt: card.image?.properties?.alternative
        }

        return {
          productData,
          productImage
        }
      })
    }
  }
})
