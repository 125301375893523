








import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropsData as UiSliderPropsData } from 'robomow-ui/src/components/UiSlider/UiSlider.types'
import getHeaderTag from '../../utils/getHeaderTag'
import { PropItems } from './CeRobomowbaseSlider.types'

@Component({ components: { UiSlider: () => import(/* webpackChunkName:"UiSlider" */'robomow-ui/src/components/UiSlider/UiSlider.vue') } })
// eslint-disable-next-line camelcase
export default class CeRobomowbase_slider extends Vue {
  @Prop() items!: PropItems

  get uiSliderPropsData (): UiSliderPropsData {
    const slides = this.items.map((item) => {
      let image
      let mobileImage

      if (item?.content.background) {
        const imageAlt = item.content.background.properties.alternative

        image = {
          url: item.content.background.publicUrl,
          urlRetina: item.content.background.urlRetina,
          urlLqip: item.content.background.urlLqip,
          alt: imageAlt || item.content.bodytext
            .replace(/(<([^>]+)>)/gi, '')
            .substring(0, 100),
          width: item.content.background.properties.dimensions.width,
          height: item.content.background.properties.dimensions.height
        }
      }

      if (item?.content?.mobileBackground) {
        mobileImage = {
          url: item.content.mobileBackground.publicUrl
        }
      }

      return {
        header: item?.content.header,
        headerTag: getHeaderTag(item?.content?.headerLayout),
        richText: item.content.bodytext,
        image,
        mobileImage
      }
    })

    return { slides } as UiSliderPropsData
  }
}
