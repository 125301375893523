// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UgLH,._2P_J{position:relative}._2P_J{height:490px;padding:.8rem 2rem;text-align:center}@media (min-width:48em){._2P_J{padding:3.2rem 2rem}}._2P_J:before{content:\"\";background:rgba(0,0,0,.5);z-index:1}._1RL3,._2P_J:before{width:100%;height:100%;position:absolute;top:0;left:0}._1RL3{background-repeat:no-repeat;background-position:50%;background-size:cover}._2HnO{width:100%;max-width:850px;display:inline-flex;flex-direction:column}._2HnO h2{text-align:left}@media (min-width:36em){._2HnO,._2HnO h2{text-align:center}}._3-_O{z-index:2}.R29o{color:#fff;z-index:2}@media (min-width:62em){.R29o p{max-width:51%;margin-top:.8rem;margin-left:auto;margin-right:auto}}@media (max-width:47.9375em){.R29o{position:absolute;bottom:3.2rem;left:2rem;padding-right:2rem}}._3wvL{z-index:2;position:absolute;display:flex;justify-content:center;align-items:center;top:0;left:0;width:100%;height:100%}@media (min-width:36em){._3wvL{margin-top:8px;position:static}}", ""]);
// Exports
exports.locals = {
	"videos": "UgLH",
	"videosOuter": "_2P_J",
	"videosItem": "_1RL3",
	"videoContent": "_2HnO",
	"videosItemTitle": "_3-_O",
	"videoText": "R29o",
	"videoPlay": "_3wvL"
};
module.exports = exports;
