import Vue from 'vue'
import SvgIcon from 'vue-svgicon'
import * as Icons from '../svg'

Vue.use(SvgIcon, {
  tagName: 'SvgIcon'
})

Object.values(Icons).forEach(icon => {
  SvgIcon.register(icon)
})
