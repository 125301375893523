/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'file': {
    width: 32,
    height: 28,
    viewBox: '0 0 32 28',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M30 0H2a2 2 0 00-2 2v24a2 2 0 002 2h28a2 2 0 002-2V2a2 2 0 00-2-2zM6.92 10a3 3 0 100-6 3 3 0 000 6zm13.86 1.37l-5.4 5.4-4-4a1 1 0 00-1.41 0L3.92 18.9v2.83l6.79-6.79L14 18.18l-3.75 3.75H13l8.45-8.45L28 20v-2.82l-5.81-5.81a1 1 0 00-1.41 0zM8.405 6.38A1.6 1.6 0 006.92 5.4v.01A1.6 1.6 0 005.33 7a1.6 1.6 0 103.075-.62zM2 2v24h28V2H2z" _fill="#373737"/>'
  }
})
