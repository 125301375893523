








import Vue, { PropType } from 'vue'
import getHeaderTag from '../../utils/getHeaderTag'
import { Header, Content, BannerGallery } from './CeRobomowproductsSeriesadvantages.types'

export default Vue.extend({
  components: {
    UiFeaturesBlock: () => import(/* webpackChunkName:"UiFeaturesBlock" */'robomow-ui/src/components/UiTextWithImages/UiFeaturesBlock')
  },
  props: {
    header: {
      type: String,
      required: true
    },
    headerLayout: {
      type: Number,
      default: 0
    },
    data: {
      type: Array as PropType<Content[]>,
      default: () => []
    }
  },
  computed: {
    main (): Header {
      return {
        header: this.header,
        headerTag: getHeaderTag(this.headerLayout)
      }
    },
    content (): BannerGallery {
      const advantages = this.data?.map((el: Content) => {
        let publicUrl
        const properties = {
          description: el.text,
          title: el.header,
          alternative: el.header
        }

        if (el.icon) {
          publicUrl = el.icon.publicUrl
        }

        return {
          publicUrl,
          properties
        }
      })

      return advantages
    }
  }
})
