





















































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import UiRichText from '../UiRichText/UiRichText.vue'
import UiIcon from '../UiIcon'
import UiVideoOverlay from '../UiVideoOverlay'
import { PropsData } from './UiVideos.types'

@Component({
  components: {
    UiRichText,
    UiIcon,
    UiVideoOverlay
  }
})
export default class UiVideos extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  showVideoOverlay = false

  @Ref('videos') videos!: HTMLDivElement
  @Ref('image') image!: HTMLImageElement

  openOverlay (): void {
    this.showVideoOverlay = true
  }

  closeOverlay (): void {
    this.showVideoOverlay = false
  }
}
