









import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'
import { ProductData, I18n, StoreLocatorButton, EmptyState } from 'robomow-ui/src/components/UiComparison/UiComparison.types'
import { ComparisonData, ComparisonImage } from './CeRobomowproductsComparison.types'
import buyButton from './../CeBuyButtonScript/buyButton'

export default Vue.extend({
  name: 'CeRobomowproductsComparison',
  components: {
    UiComparison: () => import(/* webpackChunkName:"UiComparison" */'robomow-ui/src/components/UiComparison')
  },
  mixins: [buyButton],
  props: {
    data: {
      type: Object as PropType<ComparisonData>,
      required: true
    },
    image: {
      type: Object as PropType<ComparisonImage>,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['showPrices']),
    productsData (): ProductData[] {
      return this.data.products.map((product: ProductData): ProductData => {
        product.hidePrice = !this.showPrices
        return product
      })
    },
    i18n (): I18n {
      const i18n = this.data?.i18n
      return {
        manageRobots: i18n?.manageRobots,
        addButton: i18n?.buttons?.add,
        removeButton: i18n?.buttons?.remove,
        buyOnlineButton: i18n?.buttons?.buyOnline
      }
    },
    locatorBtnData (): StoreLocatorButton {
      return this.data?.storeLocatorButton
    },
    emptyStateData (): EmptyState {
      const image = this.image as ComparisonImage
      return {
        text: this.data?.i18n?.emptyText,
        btnText: this.data?.browseProductsButton?.label,
        btnUrl: this.data?.browseProductsButton?.url,
        image: image.publicUrl,
        alt: image?.properties?.alternative
      }
    }
  }
})
