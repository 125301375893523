/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'external': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M15.667 8.25l2 2L15 12.917l1.333 1.333L19 11.583l2 2V8.25h-5.333z" _fill="#262425"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M8.938 8.92c0-.37.301-.67.674-.67h4.726V9.6h-4.05v10.8h9.45V15h1.35v-3.375 9.445a.675.675 0 01-.67.68H9.607a.675.675 0 01-.67-.67V8.92z" _fill="#262425"/>'
  }
})
