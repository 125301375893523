var render = function () {
var _obj, _obj$1, _obj$2, _obj$3;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$style[_vm.layoutClass],
    ( _obj = {}, _obj[_vm.$style.backgroundAbsolute] = _vm.isBackgroundAbsolute, _obj ),
    ( _obj$1 = {}, _obj$1[_vm.$style.bannerNoImage] = !_vm.isImageAdded, _obj$1 )
  ]},[_c('div',{class:[
      _vm.$style.mainBanner,
      ( _obj$2 = {}, _obj$2[_vm.$style.bannerContent__CENTER] = _vm.isCenter, _obj$2 ),
      ( _obj$3 = {}, _obj$3[_vm.$style.bannerImg__LEFT] = _vm.isImgLeft, _obj$3 )
    ]},[_c('div',{class:['lazyload', _vm.$style.bannerImage],attrs:{"data-bg":_vm.backgroundImage}}),_vm._v(" "),_c('div',{class:['container', _vm.$style.bannerWrapper]},[_c('div',[_vm._t("breadcrumbs")],2),_vm._v(" "),_c('div',{class:['row', _vm.$style.bannerRow]},[_c('div',{class:[_vm.$style.bannerColumn, 'col-lg-7']},[(_vm.bannerData.header)?_c(_vm.bannerData.headerTag || 'h2',{directives:[{name:"rowspans",rawName:"v-rowspans"}],tag:"component",class:['hightlighted-text h2',_vm.$style.bannerHeader]},[_vm._v("\n            "+_vm._s(_vm.bannerData.header)+"\n          ")]):_vm._e(),_vm._v(" "),_c('UiRichText',{class:_vm.$style.bannerText,attrs:{"content":_vm.bannerData.richText}}),_vm._v(" "),(_vm.bannerData.link && _vm.bannerData.link.url)?_c('UiLink',{class:['btn btn--l', _vm.$style.bannerBtn],attrs:{"to":_vm.bannerData.link.url,"target":_vm.bannerData.link.target}},[_vm._v("\n            "+_vm._s(_vm.bannerData.link.title)+"\n            "),(_vm.buyButton.buyButtonEan)?_c('UiIcon',{attrs:{"name":"geolocation","height":"20"}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.buyButton.buyButtonEan)?_c('UiBuyButton',{class:_vm.$style.bannerBtn,attrs:{"data":_vm.buyButton}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{class:['container', _vm.$style.bannerRow, _vm.$style.rowImg]},[_c('div',{class:['col-lg-7', _vm.$style.bannerColumn, _vm.$style.columnImg ]},[(_vm.bannerGallery.length > 0)?_c('UiImage',{class:_vm.$style.img,attrs:{"image":{
            publicUrl: _vm.bannerGallery[0].publicUrl,
            alt: _vm.bannerGallery[0].properties.title
          }}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }