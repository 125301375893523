




















import Vue, { PropType } from 'vue'
import UiVideos from 'robomow-ui/src/components/UiVideos'
import { PropsData } from 'robomow-ui/src/components/UiVideos/UiVideos.types'
import { SeriesInfoData, SeriesInfoImage } from 'robomow-ui/src/components/UiSeriesInfo/UiSeriesInfo.types'
import { Guide } from 'robomow-ui/src/components/UiInstallationGuides/UiInstallationGuides.types'
import getHeaderTag from '../../utils/getHeaderTag'
import { SeriesInfo, Video, GuideCover, Appearance } from './CeRobomowbaseInstallationguide.types'

export default Vue.extend({
  name: 'CeRobomowbaseInstallationguide',
  components: {
    UiSeriesInfo: () => import(/* webpackChunkName:"UiSeriesInfo" */'robomow-ui/src/components/UiSeriesInfo'),
    UiInstallationGuides: () => import(/* webpackChunkName:"UiInstallationGuides" */'robomow-ui/src/components/UiInstallationGuides'),
    UiVideos
  },
  props: {
    seriesInfo: {
      type: Object as PropType<SeriesInfo>,
      default: () => ({})
    },
    video: {
      type: [Object, String] as PropType<Video | ''>,
      default: () => ({})
    },
    files: {
      type: Array as PropType<GuideCover[]>,
      default: () => []
    },
    appearance: {
      type: Object as PropType<Appearance>,
      default: () => ({})
    }
  },
  computed: {
    layout (): string {
      return (this.appearance as Appearance).layout
    },
    seriesHeaderData (): SeriesInfoData {
      const content = (this.seriesInfo as SeriesInfo).content

      const badges = content?.data?.icons?.map((icon) => {
        return {
          src: icon.icon?.publicUrl,
          title: icon.caption,
          alt: icon.icon?.properties?.alternative
        }
      })

      return {
        header: content?.header,
        headerTag: getHeaderTag(content.headerLayout),
        description: content.subheader,
        buttonText: content?.data?.label,
        buttonLink: content?.data?.url,
        badges,
        smallButton: true
      }
    },
    seriesHeaderImage (): SeriesInfoImage {
      const seriesImage = (this.seriesInfo as SeriesInfo)?.content?.data?.image

      return {
        url: seriesImage?.publicUrl,
        urlRetina: seriesImage?.urlRetina,
        urlLqip: seriesImage?.urlLqip,
        alt: seriesImage?.properties?.alternative
      }
    },
    uiVideoPropsData (): PropsData {
      const content = (this.video as Video)?.content
      let ytId
      if (!content?.gallery?.rows['1']?.columns['1']) {
        ytId = ''
      } else {
        ytId = content?.gallery?.rows['1']?.columns['1']?.publicUrl.match(/embed\/(.*)\?/)
      }
      ytId = ytId && ytId.length === 2 ? ytId[1] : ''

      const headerType = getHeaderTag(content?.headerLayout)

      return {
        title: content?.header,
        headerTag: headerType,
        description: content?.bodytext,
        youtubeId: ytId
      }
    },
    guidesContent (): Guide[] {
      return this.files.map((file: GuideCover): Guide => {
        const content = file?.content
        return {
          title: content?.name,
          extension: content?.file?.properties?.extension,
          size: content?.file?.properties?.size,
          description: content?.description,
          buttonLabel: content?.buttonLabel,
          publicUrl: content?.file?.publicUrl,
          image: {
            publicUrl: content?.cover?.publicUrl,
            urlRetina: content?.cover?.urlRetina,
            urlLqip: content?.cover?.urlLqip,
            alt: content?.cover?.properties?.alternative
          }
        }
      })
    }
  }
})
