





























































































































































import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import UiIcon from '../../UiIcon'
import UiButton from '../../UiButton'
import UiSpinner from '../../UiSpinner'
import UiImage from '../../UiImage'
import Vue, { PropType } from 'vue'
import type { I18n, SearchData, SearchDocument, FuseResult } from '../UiHeader.types'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

export default Vue.extend({
  name: 'UiHeaderSearch',
  components: {
    UiIcon,
    UiButton,
    UiSpinner,
    UiImage,
    PerfectScrollbar
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    i18n: {
      type: Object as PropType<I18n>,
      required: true
    },
    documents: {
      type: Array as PropType<SearchDocument[]>,
      required: true
    }
  },
  data () {
    return {
      isLoading: true,
      fuse: undefined,
      query: '',
      product: [],
      news: [],
      page: [],
      inputDebouce: null as unknown as number

    } as SearchData
  },
  computed: {
    resultsCounter (): number {
      return this.product.length + this.news.length + this.page.length
    }
  },
  watch: {
    documents (nextVal) {
      // By default documents is empty array, after first click the data and Fuse will be fetched and initialized
      nextVal.length > 0 && this.initFuse()
    },
    query (nextVal: string) {
      clearTimeout(this.inputDebouce)
      this.inputDebouce = window.setTimeout(() => {
        this.product = []
        this.news = []
        this.page = []
        if (nextVal.length < 2) {
          return
        }
        const results: FuseResult[] = this.fuse.search(nextVal.trim())

        results.forEach(result => {
          const type = result.item?.type
          if (this[type]?.push) {
            this[type].push(result.item)
          }
        })

        this.$gtm && this.$gtm.push({
          event: 'user action',
          gaEventCategory: 'Sitesearch',
          gaEventAction: nextVal, // Search term
          gaEventLabel: results.length.toString(), // Number of results
          gaEventValue: '2'
        })
      }, 700)
    }
  },
  methods: {
    async initFuse () {
      try {
        const Fuse = (await import('fuse.js-ie11')).default

        this.fuse = new Fuse(this.documents, {
          ignoreLocation: true,
          ignoreFieldNorm: true,
          minMatchCharLength: 3,
          threshold: 0.3,
          includeScore: true,
          includeMatches: true,
          keys: ['title', 'content', 'description', 'productCategory']
        })

        this.isLoading = false
        if (this.isOpen) {
          (this.$refs.input as HTMLElement)?.focus()
        }
      } catch (e) {
        console.log(e)
      }
    },
    toggleSearch (): void {
      this.$emit('set-mode', this.isOpen ? '' : 'SEARCH', this.$refs.results)

      this.$nextTick(() => {
        if (this.isOpen) {
          // Emmit again to lock scroll after DOM update
          this.$emit('set-mode', 'SEARCH', this.$refs.results)
        }
        if (!this.isLoading) {
          (this.$refs.input as HTMLElement)?.focus()
        }
      })
    }
  }
})
