/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chevron': {
    width: 16,
    height: 9,
    viewBox: '0 0 16 9',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.998 5.99L2.4.5a.36.36 0 00-.508.007l-.984.981A.361.361 0 00.902 2L7.738 8.7c.07.072.167.105.26.098.097.003.19-.03.26-.098l6.84-6.703a.361.361 0 00-.006-.509l-.984-.981A.36.36 0 0013.6.5L7.998 5.99z" _fill="#373737"/>'
  }
})
