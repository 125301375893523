/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'close': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.127 5l6.86 6.998L3.127 19a.451.451 0 00.008.635l1.227 1.23c.176.177.463.18.636.008L12 13.727l7.002 7.146c.173.173.46.169.636-.008l1.227-1.23a.451.451 0 00.008-.635l-6.86-7.002L20.873 5a.451.451 0 00-.008-.635l-1.227-1.23a.451.451 0 00-.636-.008L12 10.27 4.998 3.127a.451.451 0 00-.636.008l-1.227 1.23A.451.451 0 003.127 5z" _fill="#000"/>'
  }
})
