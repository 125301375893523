/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'geolocation': {
    width: 21,
    height: 20,
    viewBox: '0 0 21 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.498 1.5a6.154 6.154 0 00-6.15 6.122c0 2.438 1.368 4.479 2.37 5.966l.183.272a52 52 0 003.205 4.175l.397.465.396-.465a52.127 52.127 0 003.205-4.175l.183-.277.006-.009c.996-1.486 2.358-3.519 2.358-5.952A6.154 6.154 0 0010.498 1.5zm0 7.396a1.947 1.947 0 100-3.894 1.947 1.947 0 000 3.894zM7.073 6.949a3.424 3.424 0 106.849 0 3.424 3.424 0 00-6.849 0z" _fill="#000"/>'
  }
})
