import { TYPO3 } from '../types/nuxt-typo3'
/**
 * Get content elements from Typo3 page object
 * @param {TYPO3.Response.Page} page Typo3 page content
 * @param {string} ceName Content elemenent type
 * @param {string} beName Backend element name
 * @returns {TYPO3.Types.ContentElement[]} array of the content elements
 */
const getCeFromPage = (page: TYPO3.Response.Page | undefined, ceName: string = 'text', beName: string = 'colPos0'): TYPO3.Types.ContentElement[] => {
  const contentElements = page?.content?.[beName] as TYPO3.Types.ContentElement[]
  return contentElements.filter(ce => ce.type === ceName)
}
export default getCeFromPage
