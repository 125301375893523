























































import Vue, { PropType } from 'vue'
import UiRichText from '../UiRichText/UiRichText.vue'
import {
  BannerData,
  BannerBackground,
  BannerIcon,
  Image,
  BannerAppearance,
  BuyProduct
} from './UiTextWithImages.types'
import UiMainBanner from './UiMainBanner'
import UiColumnBanner from './UiColumnBanner'
import { EventBus } from '../../plugin'

export default Vue.extend({
  name: 'UiTextWithImages',
  components: {
    UiRichText,
    UiMainBanner,
    UiColumnBanner,
    UiFeaturesBlock: () => import(/* webpackChunkName:"UiFeaturesBlock" */'./UiFeaturesBlock'),
    UiProductBanner: () => import(/* webpackChunkName:"UiProductBanner" */'./UiProductBanner'),
    UiAboutContent: () => import(/* webpackChunkName:"UiAboutContent" */'./UiAboutContent'),
    UiBanerImageWithNotes: () => import(/* webpackChunkName:"UiBanerImageWithNotes" */'./UiBanerImageWithNotes'),
    UiBannerMobileApps: () => import(/* webpackChunkName:"UiBannerMobileApps" */'./UiBannerMobileApps')
  },
  props: {
    bannerData: {
      type: Object as PropType<BannerData>,
      required: true
    },
    background: {
      type: Object as PropType<BannerBackground>,
      default: null
    },
    icon: {
      type: Object as PropType<BannerIcon>,
      default: null
    },
    bannerGallery: {
      type: Array as PropType<Image[]>,
      required: true
    },
    appearance: {
      type: Object as PropType<BannerAppearance>,
      required: true
    },
    buyButton: {
      type: Object as PropType<BuyProduct>,
      default: () => { return {} as BuyProduct }
    }
  },
  computed: {
    layoutClass (): string {
      return this.appearance.layout
    },
    isTextCenter (): boolean {
      return this.bannerData.textAlignment === 'center'
    },
    isMasthead (): boolean {
      return this.appearance.layout === 'masthead'
    },
    isFeatures (): boolean {
      return this.appearance.layout === 'features'
    },
    isProduct (): boolean {
      return this.appearance.layout === 'product-banner'
    },
    isColumns (): boolean {
      return this.appearance.layout === 'columns' || this.appearance.layout === 'columns-top-header'
    },
    isAbout (): boolean {
      return this.appearance.layout === 'about-us'
    },
    isDefault (): boolean {
      return this.appearance.layout === 'default'
    },
    isImagesFootnotes (): boolean {
      return this.appearance.layout === 'image-footnotes'
    },
    isMobileApps (): boolean {
      return this.appearance.layout === 'mobile-apps'
    }
  },
  mounted () {
    const wrapp = this.$refs.wrapp as HTMLElement
    wrapp.querySelector('#sparePartsLink')?.addEventListener('click', this.sparePartsLinkHandler)
  },
  beforeDestroy () {
    const wrapp = this.$refs.wrapp as HTMLElement
    wrapp.querySelector('#sparePartsLink')?.removeEventListener('click', this.sparePartsLinkHandler)
  },
  methods: {
    sparePartsLinkHandler (e: Event): void {
      EventBus.$emit('submit-spare-parts', e)
    }
  }

})

