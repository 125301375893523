


















import Vue, { PropType } from 'vue'
import UiIcon from '../UiIcon'
import { SocialIcons, ComputedSocialIcon } from './UiSocialIcons.types'

export default Vue.extend({
  name: 'UiSocialIcons',
  components: {
    UiIcon
  },
  props: {
    socialIcons: {
      type: Array as PropType<SocialIcons>,
      required: true
    }
  },
  computed: {
    icons (): ComputedSocialIcon[] {
      const icons = this.socialIcons
      const data = icons?.map(icon => {
        return {
          title: icon.title ? icon.title.toLowerCase() : '',
          url: icon.link,
          target: icon.target
        }
      })

      return data
    }
  }
})
