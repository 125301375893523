










import Vue from 'vue'
import { mapState } from 'vuex'
import UiFooter from 'robomow-ui/src/components/UiFooter'
import { I18n } from 'robomow-ui/src/components/UiFooter/UiFooter.types'
import { RobomowState } from '../../store/index'
import urls from '~typo3/mixins/utils/urls'

export default Vue.extend({
  name: 'CwFooter',
  components: {
    UiFooter
  },
  mixins: [urls],
  computed: {
    ...mapState({
      footerMenu: (state: RobomowState) => state.typo3?.initial?.footerMenu,
      footerSocialMenu: (state: RobomowState) => state.typo3?.initial?.socialMenu,
      footerContactData: (state: RobomowState) => state.typo3?.initial?.footerContactData,
      translations: (state: RobomowState) => state.typo3?.initial?.configuration?.translations
    }),
    logoUrl () : string {
      return this.getHomeUrl()
    },
    footerTranslations () : I18n {
      return {
        socialLabel: this.translations?.general.socialHeader
      }
    }
  }
})
