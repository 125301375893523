// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2yUC{min-height:20rem}@media (min-width:48em){._2yUC{min-height:88rem;height:calc(100vh - 21rem);position:relative}._2yUC>div{position:absolute;width:100%}}._1D68{display:flex;justify-content:center}@media (max-width:47.9375em){._1D68{padding-top:2rem}}@media (min-width:48em){._1D68{margin-top:5.5rem}}", ""]);
// Exports
exports.locals = {
	"placeholderArea": "_2yUC",
	"placeholderAreaSpinner": "_1D68"
};
module.exports = exports;
