import Vue from 'vue'

import CeBreadcrumbs from '~/components/CeBreadcrumbs'
import CeRobomowbaseSlider from '~/components/CeRobomowbaseSlider'
import CeText from '~/components/CeText'
import CeTextpic from '~/components/CeTextpic'
import CeHeader from '~/components/CeHeader'
import CeTextmedia from '~/components/CeTextmedia/CeTextmedia'
import CeFrame from '~/components/CeFrame/CeFrame'
import CeTestimonialsTestimonialslist from '~/components/CeTestimonialsTestimonialslist'
import CeTestimonialsTestimonialsdetail from '~/components/CeTestimonialsTestimonialsdetail'
import CeRobomowproductsCards from '~/components/CeRobomowproductsCards'
import CeRobomowproductsComparison from '~/components/CeRobomowproductsComparison'
import CeRobomowproductsProductdetailview from '~/components/CeRobomowproductsProductdetailview'
import CeRobomowproductsSeriesinfo from '~/components/CeRobomowproductsSeriesinfo'
import CeRobomowproductsSerieslist from '~/components/CeRobomowproductsSerieslist'
import CeRenderer from '~/components/CeRenderer/CeRenderer'
import CeDynamic from '~/components/CeDynamic/CeDynamic'
import CeRobomowproductsAccessorieslist from '~/components/CeRobomowproductsAccessorieslist'
import CeNewsPi1 from '~/components/CeNewsPi1/CeNewsPi1'
import CeFormFormframework from '~/components/CeFormFormframework'
import CeMtdstorelocatorStorelocations from '~/components/CeMtdstorelocatorStorelocations'
import CeMtdstorelocatorCountryswitcher from '~/components/CeMtdstorelocatorCountryswitcher'
import CeRobomowbaseInstallationguide from '~/components/CeRobomowbaseInstallationguide'
import CeMtdbaseTimeline from '~/components/CeMtdbase_timeline'
import CeRobomowproductsSeriesadvantages from '~/components/CeRobomowproductsSeriesadvantages'
import CeTxRobomowproductsProductfilters from '~/components/CeTxRobomowproductsProductfilters'
import CeRobomowbaseSeriescards from '~/components/CeRobomowbaseSeriescards'

const components = {
  CeBreadcrumbs,
  CeRobomowbaseSlider,
  CeText,
  CeTextpic,
  CeHeader,
  CeTextmedia,
  CeFrame,
  CeTestimonialsTestimonialslist,
  CeTestimonialsTestimonialsdetail,
  CeRenderer,
  CeDynamic,
  CeRobomowproductsCards,
  CeRobomowproductsComparison,
  CeRobomowproductsProductdetailview,
  CeRobomowproductsSeriesinfo,
  CeRobomowproductsSerieslist,
  CeRobomowproductsAccessorieslist,
  CeNewsPi1,
  CeFormFormframework,
  CeTxRobomowproductsProductfilters,
  CeMtdstorelocatorStorelocations,
  CeMtdstorelocatorCountryswitcher,
  CeRobomowbaseInstallationguide,
  CeMtdbaseTimeline,
  CeRobomowproductsSeriesadvantages,
  CeRobomowbaseSeriescards
}

export default () => {
  Object.keys(components).forEach((key) => {
    Vue.component(key, components[key])
  })
}
