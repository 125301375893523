






import Vue from 'vue'

export default Vue.extend({
  name: 'UiDisclaimerBox',
  props: {
    content: {
      type: String,
      default: 'Recommended retail price of the manufacturer'
    }
  }
})
