












import UiRichText from '../UiRichText'

export default {
  name: 'UiError',
  components: {
    UiRichText
  },
  props: {
    bodytext: {
      type: String,
      default: ''
    },
    code: {
      type: [String, Number],
      default: '500'
    }
  }
}
