// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3qcw{font-size:1.4rem;display:block;text-align:right;margin-top:.8rem;margin-bottom:1.6rem}html[dir=rtl] ._3qcw{text-align:left}", ""]);
// Exports
exports.locals = {
	"text": "_3qcw"
};
module.exports = exports;
