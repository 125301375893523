<template>
  <component
    :is="tagName"
    class="btn"
    :class="buttonClass"
    v-bind="[$props, $attrs]"
    v-on="$listeners"
    @click="clickHandler"
  >
    <!-- @slot Default content for button -->
    <slot />
  </component>
</template>
<script>
export default {
  name: 'UiButton',
  props: {
    tagName: {
      type: String,
      required: false,
      default: 'button'
    },
    /*
    Available variant modifiers: dark, light
    */
    variant: {
      type: String,
      default: ''
    },
    /*
    Available size modifiers: m, l, xl
    */
    size: {
      type: String,
      default: ''
    }
  },
  computed: {
    buttonClass () {
      return {
        'btn--dark': this.variant === 'dark',
        'btn--light': this.variant === 'light',
        'btn--m': this.size === 'm',
        'btn--l': this.size === 'l',
        'btn--xl': this.size === 'xl'
      }
    }
  },
  methods: {
    clickHandler () {
      this.$gtm && this.$gtm.push({
        event: 'user action',
        gaEventCategory: 'buttonclick',
        gaEventAction: this.$store.getters.title || document.title, // PageTitle
        gaEventLabel: this.$route.path, // PagePath
        gaEventValue: '1' // Fest =  1
      })
    }
  }
}
</script>
<style lang="scss">
@import "../../styles/shared";

// Default button is S size with yellow background
.btn {
  min-width: 170px;
  max-width: 100%;
  min-height: 4.8rem;
  margin: 0.8rem 0;
  padding: 0.4rem 1rem;
  font-size: 1.6rem;
  line-height: 2rem;
  color: $color-text-primary;
  background: $color-brand-secondary;
  border: none;
  outline: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: $shadow-primary;
  cursor: pointer;
  transition: color $transition-duration $transition-function, background-color $transition-duration $transition-function;

  @include media-query(lg) {
    min-height: auto;
  }

  img,
  svg {
    margin: 0 0.5rem;
  }

  @include media-query(lg) {
    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      background: $color-dark-hover;
      color: $color-text-secondary;
    }
  }

  // Style modifiers
  &--dark {
    color: $color-text-secondary;
    background: $color-brand-primary;
  }

  &--light {
    background: $color-neutral;
  }

  // Size modifiers
  &--m {
    padding: 0.5rem 1rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  &--l {
    min-width: 280px;
    padding: 1.4rem 2rem;
    font-size: 1.8rem;
  }

  &--xl {
      width: 100%;
      padding: 1.6rem 2rem;
      font-size: 2rem;
      line-height: 2.4rem;
      @include media-query(md) {
        max-width: 500px;
      }
  }

  // Disabled
  &--disabled,
  &[disabled],
  &:disabled {
    opacity: .35;
    cursor:not-allowed
  }
}
</style>
