import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { RobomowState } from '../../store/index'
import { Data } from './buyButton.types'

export default Vue.extend({
  computed: {
    ...mapGetters(['showBuyButton']),
    ...mapState({
      config: (state: RobomowState) => state.typo3?.initial?.configuration,
      langCode: (state: RobomowState) => state.typo3?.initial?.languages?.[0]?.twoLetterIsoCode,
      hrefLang: (state: RobomowState) => state.typo3?.initial?.languages?.[0]?.hreflang
    }),
    script (): Data {
      return {
        token: this.config?.commerceConnector?.token,
        widget: this.config?.commerceConnector?.widgetId
      }
    }
  },
  mounted () {
    if (this.showBuyButton &&
      this.script.token &&
      this.script.widget &&
      this.langCode &&
      this.hrefLang
    ) {
      const { set } = this.$meta().addApp('custom')
      set({
        script: [{
          id: 'cci-widget',
          src: 'https://fi-v2.global.commerce-connector.com/cc.js',
          'data-token': this.script.token,
          'data-locale': this.hrefLang,
          'data-displaylanguage': this.langCode,
          'data-widgetid': this.script.widget,
          'data-subid': 'robomow',
          'data-button-textcolor': '#f9e300',
          'data-button-backgroundcolor': '#d52b1e',
          'data-button-hover-textcolor': '#d52b1e',
          'data-button-hover-backgroundcolor': '#f9e300',
          'data-eventgtm': true,
          async: true,
          defer: true
        }]
      })
    }
  }
})
