













import Vue from 'vue'
import { mapState } from 'vuex'
import UiDisclaimerBox from 'robomow-ui/src/components/UiDisclaimerBox'
import { RobomowState } from '../../store/index'

export default Vue.extend({
  name: 'CwDisclaimerBox',
  components: {
    UiDisclaimerBox
  },
  computed: {
    ...mapState({
      disclaimer: (state: RobomowState) => state.typo3?.initial?.configuration?.translations?.general?.disclaimer || '',
      showDisclaimer: (state: RobomowState) => state.typo3?.page?.page?.settings?.showDisclaimer,
      additionalDisclaimer: (state: RobomowState) => state.typo3?.initial?.configuration?.translations?.general?.additionalDisclaimer || '',
      showAdditionalDisclaimer: (state: RobomowState) => state.typo3?.page?.page?.settings?.showAdditionalDisclaimer
    })
  }
})
