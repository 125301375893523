








import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'
import { SeriesInfoData, SeriesInfoImage, BadgeIcon } from 'robomow-ui/src/components/UiSeriesInfo/UiSeriesInfo.types'
import getHeaderTag from '../../utils/getHeaderTag'
import { SeriesBanerData, Icon, Image } from './CeRobomowproductsSeriesinfo.types'

export default Vue.extend({
  name: 'CeRobomowproductsSeriesinfo',
  components: {
    UiSeriesInfo: () => import(/* webpackChunkName:"UiSeriesInfo" */'robomow-ui/src/components/UiSeriesInfo')
  },
  props: {
    data: {
      type: Object as PropType<SeriesBanerData>,
      required: true
    },
    header: {
      type: String,
      default: 'Robomow'
    },
    subheader: {
      type: String,
      default: 'A beautiful robot for your beautiful garden.'
    },
    headerLayout: {
      type: Number,
      default: 0
    },
    image: {
      type: Object as PropType<Image>,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['showPrices']),
    contentData () : SeriesInfoData {
      const badges = this.data.icons?.map((icon: Icon) : BadgeIcon => {
        return {
          title: icon.caption,
          src: icon.icon?.publicUrl,
          alt: icon.icon?.properties?.alternative
        }
      })
      return {
        header: this.header,
        headerTag: getHeaderTag(this.headerLayout),
        description: this.subheader,
        price: this.data.priceTag,
        hidePrice: !this.showPrices,
        buttonText: this.data.label,
        buttonLink: this.data.url,
        badges
      }
    },
    imageData () : SeriesInfoImage {
      return {
        url: this.data.image?.publicUrl,
        urlRetina: this.data.image?.urlRetina,
        urlLqip: this.data.image?.urlLqip,
        alt: this.data.image?.properties?.alternative,
        alignment: this.data.imageOrientation
      }
    },
    warrantyIcon () {
      const image = this.image as Image
      return {
        url: image?.publicUrl,
        urlLqip: image?.publicUrl,
        urlRetina: image?.publicUrl,
        alt: image?.properties?.alternative
      }
    }
  }
})
