



















































import Vue from 'vue'
import UiIcon from '../UiIcon'

export default Vue.extend({
  name: 'UiVideoOverlay',
  components: {
    UiIcon
  },
  props: {
    src: {
      type: String,
      default: ''
    },
    ytId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      userMarketingAgreement: false
    }
  },
  computed: {
    showIframe (): boolean {
      return this.ytId !== ''
    }
  },
  /**
   * Cookiebot docs https://www.cookiebot.com/en/developer/
   */
  mounted () {
    this.setMarketingAgreement()
    // eslint-disable-next-line @typescript-eslint/camelcase
    window.CookiebotCallback_OnAccept = () => {
      this.setMarketingAgreement()
    }
  },
  methods: {
    setMarketingAgreement (): void {
      try {
        this.userMarketingAgreement = window.Cookiebot.consent.marketing
      } catch (e) {
        console.log(e)
      }
    },
    hadleCookiebotRenew (): void {
      window.Cookiebot?.renew()
    }
  }
})
