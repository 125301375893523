<script>
import Vue from 'vue'

export default {
  // Switch between <nuxt-link> and standard <a>
  name: 'UiLink',
  functional: true,
  inheritAttrs: false,
  render (createElement, { props, data, children }) {
    const isExternal = /^(http:|https:|ftp:|mailto:)/.test(data.attrs.to)
    // Render <a> for external url, or nav component for internal url
    const linkTag = isExternal ? 'a' : Vue.component('NuxtLink')?.extendOptions.name || Vue.component('RouterLink')?.extendOptions.name
    return createElement(
      linkTag,
      {
        ...data,
        attrs: {
          ...data.attrs,
          ...(isExternal ? { href: props.to } : {})
        },
        props: {
          ...props,
          ...(isExternal ? {} : { to: props.to || '/' })
        }
      },
      children
    )
  }
}
</script>
