export default function ({ $gtm, $typo3 }) {
  const locale = $typo3.i18n.getLocaleCodePath()
  const splittedLocale = locale.split('-')
  const lang = splittedLocale[0]
  const region = splittedLocale[1]
  window.addEventListener('load', () => {
    let source
    try {
      // eslint-disable-next-line no-undef
      source = document.referrer || referringInfo.source || 'na'
    } catch {
      source = 'na'
    }
    $gtm.push({
      sessionInfo: {
        country: region || 'INT', // country page, by default 'INT'
        language: lang || 'en', // what language, by default 'en'
        source // document.referrer OR referringInfo.source; ???  ||| else = na
      }
    })
  })

  window.CCEventTrackingCallback = function (eventType, eventContext) {
    // Commerce connector docs: https://admin.global.commerce-connector.com/print-view/documentation
    if (eventType === 'shop_clickout') {
      try {
        $gtm.push({
          event: 'user action',
          gaEventCategory: 'buynow',
          gaEventAction: eventContext.data_ean, // product
          gaEventLabel: eventContext.shop_name, // name of shop
          gaEventValue: '30'
        })
      } catch {}
    }
  }
}
