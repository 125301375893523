var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'row',
    _vm.$style.columnBanner,
    ( _obj = {}, _obj[_vm.$style.columnBanner__IMG_LEFT] = _vm.toLeft, _obj )
  ],style:({'text-align': _vm.textAlignment})},[(_vm.isTopHeader)?_c('div',{staticClass:"col-12"},[_c(_vm.headerTag,{tag:"component",class:['h2',_vm.$style.header]},[_vm._v("\n      "+_vm._s(_vm.bannerData.header)+"\n    ")])],1):_vm._e(),_vm._v(" "),_c('div',{class:['col-md-6', _vm.$style.column]},[_c('div',{class:[_vm.$style.content]},[(!_vm.isTopHeader)?_c(_vm.headerTag,{tag:"component",class:['h2',_vm.$style.header]},[_vm._v("\n        "+_vm._s(_vm.bannerData.header)+"\n      ")]):_vm._e(),_vm._v(" "),_c('UiRichText',{class:_vm.$style.text,attrs:{"content":_vm.bannerData.richText}}),_vm._v(" "),(_vm.bannerData.link && _vm.bannerData.link.url)?_c('UiButton',{attrs:{"tag-name":"UiLink","href":_vm.bannerData.link.url,"variant":"dark","size":"l"}},[_vm._v("\n        "+_vm._s(_vm.bannerData.link.title)+"\n      ")]):_vm._e()],1)]),_vm._v(" "),_c('div',{class:['col-md-6', _vm.$style.column]},[(_vm.bannerGallery.length > 0)?_c('UiImage',{class:_vm.$style.columnImg,attrs:{"image":{
        publicUrl: _vm.bannerGallery[0].publicUrl,
        alt: _vm.bannerGallery[0].properties.title
      }}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }