/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'youtube': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M29.333 16s0-4.96-.609-7.336c-.335-1.31-1.324-2.341-2.584-2.696C23.861 5.333 16 5.333 16 5.333s-7.857 0-10.14.635c-1.255.35-2.245 1.383-2.584 2.696-.61 2.376-.61 7.336-.61 7.336s0 4.96.61 7.336c.335 1.31 1.324 2.341 2.584 2.696 2.283.635 10.14.635 10.14.635s7.861 0 10.14-.635c1.255-.35 2.245-1.383 2.584-2.696.61-2.376.61-7.336.61-7.336zm-8 0l-8 4.667v-9.334l8 4.667z" _fill="#F8F8F8"/>'
  }
})
