




























import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import { NewsItem, NewsList, MediaItem } from 'robomow-ui/src/components/UiNewsList/UiNewsList.types'
import getHeaderTag from '../../utils/getHeaderTag'
import { RobomowState } from '../../store/index'
import { News, NewsData } from './CeNewsPi1.types'

export default Vue.extend({
  components: {
    UiNewsList: () => import(/* webpackChunkName:"UiNewsList" */'robomow-ui/src/components/UiNewsList'),
    UiSingleNews: () => import(/* webpackChunkName:"UiSingleNews" */'robomow-ui/src/components/UiSingleNews'),
    UiPagination: () => import(/* webpackChunkName:"UiPagination" */'robomow-ui/src/components/UiPagination')
  },
  props: {
    data: {
      type: Object as PropType<NewsData>,
      required: true
    },
    header: {
      type: String,
      default: 'Related posts'
    },
    headerLayout: {
      type: Number,
      default: 0
    },
    linkLabel: {
      type: String,
      default: 'Read full story'
    }
  },
  computed: {
    ...mapState({
      slug: (state: RobomowState) => state.typo3?.page?.page?.navigation?.slug
    }),
    isNewsList () : boolean {
      return this.data.settings.action === 'list'
    },
    isSingleNews () : boolean {
      return this.data.settings.action === 'detail'
    },
    headerTag () : string {
      return getHeaderTag(this.headerLayout)
    },
    listData () : NewsList {
      return this.data.list.map((news : News) : NewsItem => {
        let media, type
        if (news.media.length) {
          media = {
            url: news.media[0].images?.listViewImage?.publicUrl,
            alt: news.media[0].properties?.alternative,
            width: news.media[0].images?.listViewImage?.dimensions?.width,
            height: news.media[0].images?.listViewImage?.dimensions?.height,
            source: news.media[0]?.properties?.source
          } as MediaItem
          type = news.media[0].type
        }

        if (type === 'video' && media) {
          let ytId
          if (!media.source) {
            ytId = ''
          } else {
            ytId = media.source.match(/v=(.*)/)
          }
          media.ytId = ytId && ytId.length === 2 ? ytId[1] : ''
        }

        return {
          title: news.title,
          teaser: news.teaser,
          datetime: news.datetime,
          slug: news.slug,
          link: this.linkLabel,
          media,
          type
        }
      })
    }
  }
})
