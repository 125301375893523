// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3bQK{width:100vw;height:calc(100% - 7.2rem);background:rgba(0,0,0,.65);display:flex;justify-content:center;align-items:flex-start;position:fixed;right:0;top:7.2rem;overflow-y:scroll;cursor:auto}@media (min-width:62em){._3bQK{align-items:center}}.vFVf{background:#fff}@media (min-width:36em){.vFVf{background:none}}.kzER{padding:.8rem 0;background:#fff}@media (min-width:36em){.kzER{padding:.8rem 2rem}}._2Z5K{width:48px;height:7.2rem;color:#fff;background:none;border:none;outline:none;display:flex;align-items:center;justify-content:center;transition:background-color .3s ease-in-out;cursor:pointer}@media (min-width:22.4375em){._2Z5K{width:60px}}@media (min-width:36em){._2Z5K{width:70px}}@media (min-width:74.375em){._2Z5K:hover{color:#000;background:#fcd700}}._3nzY{color:#000;background:#fcd700}._1MwY{margin-top:16px;position:relative;padding-bottom:1rem}.MqYi{margin:0 0 16px;text-align:left;display:inline-block}._1eNZ{font-size:1.8rem;font-size:var(--font-size-menu,1.8rem);line-height:2.4rem;line-height:var(--line-height-menu,2.4rem);font-weight:500;padding:.4rem 0;color:#000;text-decoration:none;display:flex;align-items:center}._1eNZ img{margin:0 1rem 0 0}.kGYI{max-width:3.2rem;max-height:2.2rem;pointer-events:none}@media (max-width:74.3125em){._147i{display:none}}._2PUu{font-family:\"futura-pt\",sans-serif;font-size:2.2rem;line-height:2.4rem;font-weight:500;display:flex;flex-direction:column}._2PUu span:nth-child(2){font-size:1.4rem;line-height:1.6rem;font-weight:400}._2PUu span:nth-child(n+3){display:none}@media (min-width:74.375em){._2PUu{display:none}}html[dir=rtl] .MqYi{text-align:right}html[dir=rtl] ._1eNZ img{margin:0 0 0 1rem}", ""]);
// Exports
exports.locals = {
	"wrapp": "_3bQK",
	"container": "vFVf",
	"frame": "kzER",
	"langSwitcher": "_2Z5K",
	"langSwitcher__open": "_3nzY",
	"list": "_1MwY",
	"item": "MqYi",
	"link": "_1eNZ",
	"flag": "kGYI",
	"flag__current": "_147i",
	"countryCode": "_2PUu"
};
module.exports = exports;
