




import Vue, { PropType } from 'vue'
import type { FormSubmitObject as UiFormSubmitObject } from 'robomow-ui/src/components/UiForm/UiForm.types'
import { TYPO3 } from '../../types/nuxt-typo3'
import type { FormLink, Form, FormState, FormApi, FormContentElement, ActionAfterSuccess } from './CeFormFormframework.types'
const defaultState : FormState = {
  sending: null,
  error: null,
  failure: null,
  success: null
}
export default Vue.extend({
  name: 'CeFormFormframework',
  components: {
    RobomowContactForm: () => import(/* webpackChunkName:"UiFormContact" */'robomow-ui/src/components/UiFormContact'),
    UiForm: () => import(/* webpackChunkName:"UiForm" */'robomow-ui/src/components/UiForm')
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    form: {
      type: Object as PropType<Form>,
      required: true
    },
    link: {
      type: Object as PropType<FormLink>,
      required: true
    }
  },
  data () {
    return {
      state: { ...defaultState } as FormState,
      response: undefined as FormContentElement
    }
  },
  computed: {
    // get shortcut for form response
    apiResponse () : FormApi | undefined {
      return this.response?.content?.form?.api
    },
    // import custom forms if exist
    formComponent () : string {
      const formNames = Object.keys(this.$options.components as unknown as string[])
      const customFormName = formNames.find(name => this.form.id.includes(name))

      if (customFormName) {
        return customFormName
      }
      return 'UiForm'
    }
  },
  methods: {
    /**
     * UiForm Submit callback
     * @param {UiFormSubmitObject} FormObject
     */
    async onSubmit ({ validator, form, formRef } : UiFormSubmitObject) {
      const { flags, setErrors } = validator
      const { resetForm } = form
      const formData = new FormData(formRef)
      if (flags.valid) {
        this.setState('sending')
        try {
          const page = await this.sendForm(formData, this.link.url)
          this.response = this.findFormElementById(page, this.id)

          if (this.apiResponse?.status === 'success') {
            this.setState('success')
            resetForm()
            return this.onSuccess(this.apiResponse.actionAfterSuccess)
          }
          if (this.apiResponse?.status === 'failure') {
            setErrors(this.prepareErrors(this.apiResponse.errors))
            this.setState('failure')
          }
        } catch (err) {
          this.setState('error')
        }
      }
    },
    /**
     * Find form content element from page colpos objects
     * @param {TYPO3.Response.Page} TYPO3 page object
     * @param {number} id of current content element
     * @returns {FormContentElement} content elemenet data
     */
    findFormElementById (page: TYPO3.Response.Page, id : number) : FormContentElement {
      let formContent : FormContentElement
      if (Object.values(page.content)) {
        formContent = Object.values(page.content).map(contentArr => contentArr.find(element => element?.id === id))?.[0]
      }
      return formContent
    },
    /**
     * Send form used $typo3 http client
     * @param {FormData} parsed form data object
     * @param {string} url API Endpoint to call contact form
     * @returns {Promise<TYPO3.Response.Page>} whole page content
     */
    async sendForm (formData: FormData, url: string): Promise<TYPO3.Response.Page> {
      return await (this.$typo3 as TYPO3.Plugin).api.$http.$post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
    },
    /**
     * Action to call on success
     * @param {ActionAfterSuccess} actionAfterSuccess object
     * @returns {void}
     */
    onSuccess (actionAfterSuccess: ActionAfterSuccess): void {
      this.$gtm && this.$gtm.push({
        event: 'user action',
        gaEventCategory: 'formsubmission',
        gaEventAction: this.$store.getters.title || document.title, // PageTitle
        gaEventLabel: this.$route.path, // PagePath
        gaEventValue: '50'
      })

      if (actionAfterSuccess?.redirectUri) {
        this.$router.push(actionAfterSuccess.redirectUri)
      }
    },
    /**
     * Map errors from api to vee-validate schema
     * @param {{[key: string]: string}} errors object
     * @returns {Record<string, string[] | string>} errors object for vee-validate
     */
    prepareErrors (errors: {[key: string]: string}): Record<string, string[] | string> {
      const errObj : Record<string, string[] | string> = { ...errors }
      Object.values(errObj).map(value => ([value]))
      return errObj
    },
    /**
     * Ui helper to determine form state
     * @param {string} current state
     * @returns {void}
     */
    setState (state: string): void {
      this.state = { ...defaultState }
      this.state[state] = true
    }
  }
})
