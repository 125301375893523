




import { Vue, Component, Prop } from 'vue-property-decorator'
import { PropsData as UiTimelinePropsData } from 'robomow-ui/src/components/UiTimeline/UiTimeline.types'
import { PropItems } from './CeMtdbase_timeline.types'

@Component({ components: { UiTimeline: () => import(/* webpackChunkName:"UiTimeLine" */'robomow-ui/src/components/UiTimeline') } })
export default class CeMtdbaseTimeline extends Vue {
  @Prop() items!: PropItems
  @Prop() header!: string

  get uiTimelinePropsData (): UiTimelinePropsData {
    const years = this.items.map((item) => {
      let image

      if (item.content.image) {
        const imageAlt = item.content.image.properties.alternative

        image = {
          url: item.content.image.publicUrl,
          urlRetina: item.content.image.urlRetina,
          urlLqip: item.content.image.urlLqip,
          alt: imageAlt || item.content.bodytext
            .replace(/(<([^>]+)>)/gi, '')
            .substring(0, 100),
          width: item.content.image.properties.dimensions.width,
          height: item.content.image.properties.dimensions.height
        }
      }

      return {
        year: item.content.year,
        richText: item.content.bodytext,
        image
      }
    })

    return {
      header: this.header,
      years
    }
  }
}
