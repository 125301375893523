/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'search': {
    width: 23,
    height: 22,
    viewBox: '0 0 23 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.22.333a8.667 8.667 0 100 17.334 8.667 8.667 0 000-17.334zm0 1.367A7.3 7.3 0 111.927 9 7.333 7.333 0 019.22 1.7zm7.533 13.88l4.914 4.947a.667.667 0 01-.947.94l-4.913-4.947.946-.94z" _fill="currentColor"/><path pid="1" d="M15.97 6.202l.23-.096-.23.096zM9.22 1.7v-.25h-.001l.001.25zm5.17 12.46l.178.176-.177-.177zm-7.955 1.585l.095-.231-.095.23zM1.927 9l-.25-.001V9h.25zm19.74 11.527l.177-.177-.177.177zm-4.914-4.947l.178-.176-.176-.178-.178.177.176.177zm5.084 5.592l.241.065-.241-.065zm-.473.47l.064.241-.064-.241zm-.644-.175l.177-.176-.177.176zm-4.913-4.947l-.177-.177-.177.176.176.177.178-.176zM.803 9A8.417 8.417 0 019.22.583v-.5A8.917 8.917 0 00.303 9h.5zm8.417 8.417A8.417 8.417 0 01.803 9h-.5a8.917 8.917 0 008.917 8.917v-.5zM17.637 9a8.417 8.417 0 01-8.417 8.417v.5A8.917 8.917 0 0018.137 9h-.5zM9.22.583A8.417 8.417 0 0117.637 9h.5A8.917 8.917 0 009.22.083v.5zm6.98 5.523A7.55 7.55 0 009.22 1.45v.5a7.05 7.05 0 016.518 4.348l.462-.192zm-1.632 8.23a7.55 7.55 0 001.632-8.23l-.462.192a7.05 7.05 0 01-1.524 7.685l.354.353zm-8.229 1.64a7.55 7.55 0 008.229-1.64l-.354-.353a7.05 7.05 0 01-7.684 1.53l-.191.463zM1.677 9a7.55 7.55 0 004.662 6.976l.191-.462A7.05 7.05 0 012.177 9h-.5zm7.542-7.55a7.583 7.583 0 00-7.542 7.549l.5.002A7.083 7.083 0 019.22 1.95l-.002-.5zm12.625 18.9l-4.913-4.946-.355.352 4.913 4.947.355-.352zm.234.887a.917.917 0 00-.234-.886l-.355.352c.105.105.146.26.107.403l.482.131zm-.65.646a.917.917 0 00.65-.646l-.482-.131a.417.417 0 01-.296.294l.128.483zm-.885-.24c.23.232.568.324.885.24L21.3 21.4a.417.417 0 01-.403-.11l-.354.353zm-4.914-4.947l4.914 4.947.354-.352-4.913-4.947-.355.352zm.948-1.293l-.947.94.353.354.947-.94-.353-.354z" _fill="currentColor"/>'
  }
})
