










































































import Vue, { PropType } from 'vue'
import UiIcon from '../../UiIcon'
import UiImage from '../../UiImage'
import type { Language } from '../UiHeader.types'
import Cookies from 'js-cookie'

export default Vue.extend({
  name: 'UiHeaderLangSwitcher',
  components: {
    UiIcon,
    UiImage
  },
  props: {
    allLang: {
      type: Array as PropType<Language[]>,
      default: () => []
    },
    currentLang: {
      type: Object as PropType<Language>,
      default: () => ({} as Language)
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    headerText: {
      type: String,
      default: 'Select your location'
    },
    btnLabel: {
      type: String,
      default: 'Choose region'
    }
  },
  computed: {
    countryLabels (): string[] {
      const labels = this.currentLang?.countryLabel?.split('-')
      if (labels && labels[1]) labels[1] = `(${labels[1]})`
      return labels
    }
  },
  methods: {
    toggleSwitcher (): void {
      this.$emit('set-mode', this.isOpen ? '' : 'LANG', this.$refs.wrapp)
      this.$nextTick(() => {
        if (this.isOpen) {
          this.$emit('set-mode', 'LANG', this.$refs.wrapp)
        }
      })
    },
    handleLangSwitch ($event: Event): void {
      $event.preventDefault()
      const targetFlag = $event.target as HTMLAnchorElement
      if (targetFlag && targetFlag.href && this.$nuxt) {
        const result = targetFlag.href.match(/\/(\w{2}-\w{2})/)
        const code = result ? result[1] : this.$nuxt.$typo3.i18n.defaultLocale

        // save selected country code to cookie
        Cookies.set('usr_lang', code, { expires: 365 })
        location.href = targetFlag.href
      }
    }
  }
})
