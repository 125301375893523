










import Vue, { PropType } from 'vue'
import { mapGetters, mapState } from 'vuex'
import { Product } from 'robomow-ui/src/components/UiAccessoriesList/UiAccessoriesList.types'
import { ProductBoxData, ProductBoxImage } from 'robomow-ui/src/components/UiProductBox/UiProductBox.types'
import { RobomowState } from '../../store/index'
import { ProductData, Products } from './CeRobomowproductsAccessorieslist.types'
import buyButton from './../CeBuyButtonScript/buyButton'

export default Vue.extend({
  components: {
    UiAccessoriesList: () => import(/* webpackChunkName:"UiAccessoriesList" */'robomow-ui/src/components/UiAccessoriesList')
  },
  mixins: [buyButton],
  props: {
    data: {
      type: Object as PropType<Products>,
      required: true
    },
    linkLabel: {
      type: String,
      default: 'Categories'
    }
  },
  computed: {
    ...mapGetters(['showPrices']),
    ...mapState({
      slug: (state: RobomowState) => state.typo3?.page?.page?.navigation?.slug
    }),
    products () : Product[] {
      return this.data.products.map((product : ProductData) : Product => {
        const details = product.attributes?.map(attribute => ({ property: attribute.name, value: attribute.value }))

        const productData : ProductBoxData = {
          header: product.name,
          price: product.price,
          hidePrice: !this.showPrices,
          description: product.description,
          buyButtonText: product.buttons?.buyButton?.label,
          buyButtonLabel: product.buttons?.buyButton?.label,
          buyButtonEan: product.buttons?.buyButton?.ean,
          buyButtonLang: '.pl',
          buyButtonName: product.buttons?.buyButton?.name,
          locatorButtonText: product.buttons?.locatorButton?.label,
          locatorButtonLabel: product.buttons?.locatorButton?.label,
          locatorButtonLink: product.buttons?.locatorButton?.url,
          linkText: product.buttons?.detailButton?.label,
          linkLabel: product.buttons?.detailButton?.label,
          link: product.buttons?.detailButton?.url,
          isAccessory: product.isAccessory,
          details
        }

        const productImage : ProductBoxImage = {
          url: product.image?.publicUrl,
          urlRetina: product.image?.urlRetina,
          urlLqip: product.image?.urlLqip,
          alt: product.image?.properties?.alternative
        }

        const accessoryFor = product?.accessoryFor?.map((accessory : string) => {
          return accessory
        })

        return {
          productData,
          productImage,
          accessoryFor
        }
      })
    }
  }
})
