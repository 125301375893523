



















import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import { TestimonialBoxData } from 'robomow-ui/src/components/UiTestimonials/UiTestimonials.types'
import getHeaderTag from '../../utils/getHeaderTag'
import { RobomowState } from '../../store/index'
import { HeaderLink } from './CeTestimonialsTestimonialslist.types'

export default Vue.extend({
  components: {
    UiTestimonials: () => import(/* webpackChunkName:"UiTestimonials" */'robomow-ui/src/components/UiTestimonials'),
    UiPagination: () => import(/* webpackChunkName:"UiPagination" */'robomow-ui/src/components/UiPagination')
  },
  props: {
    header: {
      type: String,
      required: true
    },
    headerLayout: {
      type: Number,
      default: 0
    },
    data: {
      type: Object as PropType<TestimonialBoxData>,
      required: true,
      default: () => {}
    },
    linkLabel: {
      type: String,
      default: ''
    },
    headerLink: {
      type: [Object, String] as PropType<HeaderLink>,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      slug: (state: RobomowState) => state.typo3?.page?.page?.navigation?.slug
    }),
    headerTag () : string {
      return getHeaderTag(this.headerLayout)
    },
    linkUrl (): string {
      const headerLink = this.headerLink as HeaderLink
      return headerLink.url
    }
  }
})
