
import { GetterTree } from 'vuex'
import { Typo3 } from '../types/typo3.types'

export interface RobomowState {
  typo3: Typo3;
}

// Example of getters definition
export type Getters = {
  showPrices(state: RobomowState): boolean
  showBuyButton(state: RobomowState): boolean
  langCode(state: RobomowState): string
  dir(stare: RobomowState): string
  socialData(state: RobomowState): object
  meta(state: RobomowState): object
  title(state: RobomowState): string
}

export const getters: GetterTree<RobomowState, RobomowState> & Getters = {
  showPrices: (state: RobomowState) => state.typo3?.initial?.configuration?.showPrices,
  showBuyButton: (state: RobomowState) => state.typo3?.initial?.configuration.commerceConnector.enabled,
  langCode: (state: RobomowState) => state.typo3?.initial?.languages?.find(lang => lang.active === 1)?.hreflang || 'en-GB',
  dir: (state: RobomowState) => state.typo3?.initial?.languages?.find(lang => lang.active === 1)?.direction || 'ltr',
  socialData: (state: RobomowState) => state.typo3?.page?.page?.socialMedia,
  meta: (state: RobomowState) => state.typo3?.page?.page?.meta,
  title: (state: RobomowState) => state.typo3?.page?.page?.meta?.title || state.typo3?.page?.page?.title
}
