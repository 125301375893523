/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cart': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M7.5 17.778A1.389 1.389 0 107.5 15a1.389 1.389 0 000 2.778zM14.722 17.778a1.389 1.389 0 100-2.778 1.389 1.389 0 000 2.778zM18.389 3.55a.557.557 0 00-.439-.217H5.117l-.25-.794a.556.556 0 00-.367-.361l-2.278-.7a.558.558 0 00-.328 1.066l1.995.612 2.544 8.038-.905.745-.072.072a1.478 1.478 0 00-.045 1.878 1.528 1.528 0 001.256.555h9.272a.555.555 0 100-1.11H6.578a.372.372 0 01-.311-.556l1.339-1.111h8.572a.556.556 0 00.555-.423l1.778-7.222a.555.555 0 00-.122-.472z" _fill="#000"/>'
  }
})
