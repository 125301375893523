/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'twitter': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M29.6 7.454c-1.003.45-2.075.752-3.205.892a5.656 5.656 0 002.456-3.126 11.01 11.01 0 01-3.545 1.37A5.543 5.543 0 0021.23 4.8c-3.084 0-5.58 2.531-5.58 5.652 0 .444.046.875.144 1.29-4.64-.234-8.755-2.486-11.505-5.91a5.706 5.706 0 00-.755 2.841 5.68 5.68 0 002.49 4.708 5.401 5.401 0 01-2.536-.706v.07c0 2.742 1.925 5.022 4.479 5.542a5.556 5.556 0 01-1.47.198c-.357 0-.709-.035-1.049-.105.709 2.246 2.772 3.879 5.216 3.926A11.104 11.104 0 012.4 24.645a15.464 15.464 0 008.542 2.555c10.277 0 15.891-8.616 15.891-16.088 0-.245-.006-.49-.017-.73 1.09-.799 2.035-1.79 2.784-2.928z" _fill="#F8F8F8"/>'
  }
})
