import CeRenderer from '~typo3/components/content/CeRenderer'
export default {
  extends: CeRenderer,
  render (createElement, ctx) {
    // render standalone component
    function renderComponent (element, index) {
      return createElement('ce-dynamic', {
        props: {
          data: element,
          type: element.type,
          index
        }
      })
    }

    // render component with frame wrapper
    function renderFrame (element, index) {
      return createElement(
        'ce-frame',
        {
          props: element.appearance
        },
        [renderComponent(element, index)]
      )
    }

    // render loop of content elements
    return ctx.props.content.map((element, index) => {
      return ctx.props.frame && element.appearance.frameClass !== 'none'
        ? renderFrame(element, index)
        : renderComponent(element, index)
    })
  }
}
