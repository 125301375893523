







import Vue, { PropType } from 'vue'
import CeRobomowproductsCards from '../CeRobomowproductsCards'
import getHeaderTag from '../../utils/getHeaderTag'
import { SeriesCard, ProductCardData } from './CeRobomowbaseSeriescards.types'

export default Vue.extend({
  name: 'CeRobomowbaseSeriescards',
  components: {
    CeRobomowproductsCards
  },
  props: {
    cards: {
      type: Array as PropType<SeriesCard[]>,
      default: () => []
    }
  },
  computed: {
    seriesData (): ProductCardData[] {
      return this.cards?.map((card) => {
        const content = card.content
        const data = card.content?.data

        const seriesButton = {
          url: data?.url,
          label: data?.label
        }

        return {
          series: content?.header,
          seriesHeaderTag: getHeaderTag(content?.headerLayout),
          seriesSubheader: content.subheader,
          price: data?.priceTag,
          icons: data?.icons,
          image: data?.image,
          buttons: {
            seriesButton
          }
        }
      })
    }
  }
})
