import langParser from 'accept-language-parser'
import cookie from 'cookie'
export default function (context) {
  if (process.server && context.req.headers['accept-language']) {
    // 1. check if path does not include specific lang / then we wont change it
    const localeCode = context.$typo3.i18n.getLocaleByPath()

    if (context.params.pathMatch.includes(localeCode) || context.route.path !== '/') {
      return true
    }

    // 2. check if cookies contain a language defined by user; if yes, check if code is presented in i18n options and redirect to it
    const requestCookies = cookie.parse(context.req.headers.cookie || '')
    if (requestCookies.usr_lang) {
      const slectedLang = requestCookies.usr_lang
      if (
        context.$typo3.i18n.locales &&
        context.$typo3.i18n.locales.find(locale => locale === slectedLang)
      ) {
        const redirectPath = slectedLang === context.$typo3.i18n.defaultLocale
          ? ''
          : `/${slectedLang}/${context.params.pathMatch}`

        return context.redirect(redirectPath, { ...context.route.query })
      }
    }

    // 3. get accept lang from browser and parse it
    let parsedLocale = langParser.parse(context.req.headers['accept-language'])
    if (parsedLocale.length) {
      const region = parsedLocale[0].region
      const code = parsedLocale[0].code
      parsedLocale = `${code || ''}${region ? '-' : ''}${region || ''}`

      // hardcoded exeptions
      const exeptionsMap = {
        de: 'de-DE',
        fr: 'fr-FR'
      }
      parsedLocale = exeptionsMap[parsedLocale] || parsedLocale
    }

    // 4. check if parsed lang is exist in available langs array
    if (
      context.$typo3.i18n.locales &&
      context.$typo3.i18n.locales.find(locale => locale === parsedLocale)
    ) {
      return context.redirect(`/${parsedLocale}/${context.params.pathMatch}`, { ...context.route.query })
    }

    return true
  }
}
