








import Vue, { PropType } from 'vue'
import { Links } from 'robomow-ui/src/components/UiStoreLocator/UiStoreLocatorCountries/UiStoreLocatorCountries.types'

export default Vue.extend({
  name: 'CeMtdstorelocatorCountryswitcher',
  components: {
    UiStoreLocatorCountries: () => import(/* webpackChunkName:"UiStoreLocatorCountries" */'robomow-ui/src/components/UiStoreLocator/UiStoreLocatorCountries')
  },
  props: {
    header: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    links: {
      type: Array as PropType<Links>,
      default: () => []
    }
  }
})
