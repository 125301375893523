/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dropdown': {
    width: 12,
    height: 8,
    viewBox: '0 0 12 8',
    data: '<path pid="0" d="M6 4.215L2.465.679.696 2.446 6 7.75l5.304-5.304L9.535.68 6 4.215z" _fill="#fff"/>'
  }
})
