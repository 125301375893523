import lazysizes from 'lazysizes'

// Lazy loading of the background images
document.addEventListener('lazybeforeunveil', function (e) {
  const bg = e.target.getAttribute('data-bg')
  if (bg) {
    e.target.style.backgroundImage = 'url(' + bg + ')'
  }
})

export default lazysizes
