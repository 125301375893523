<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    :class="$style.content"
    v-html="content"
  />
</template>

<script>
// source: https://github.com/nuxt/nuxtjs.org/blob/master/components/HtmlParser.vue
export default {
  name: 'UiRichText',
  props: {
    /**
     * HTML Content to parse
     */
    content: {
      type: String,
      required: true,
      default: 'Lorem ipsum'
    }
  },
  watch: {
    content: 'contentUpdated'
  },
  mounted () {
    this.$nextTick(this.addListeners)
  },
  beforeDestroy () {
    this.removeListeners()
  },
  methods: {
    gaEventsHandler (event) {
      const target = event.target
      const href = target.getAttribute('href')
      if (href && href.includes('mailto:')) {
        // Mail link event
        const splittedMail = href.split(':')
        this.$gtm.push({
          event: 'user action',
          gaEventCategory: 'EmailClick',
          gaEventAction: splittedMail[1], // Mail Address
          gaEventLabel: this.$route.path, // PagePath
          gaEventValue: '10'
        })
      } else if (href && href.includes('tel:')) {
        // Tel link event
        const splittedTel = href.split(':')
        this.$gtm.push({
          event: 'user action',
          gaEventCategory: 'PhoneNumberClick',
          gaEventAction: splittedTel[1], // VAR =  Number
          gaEventLabel: this.$route.path, // VAR = PagePath
          gaEventValue: '10'
        })
      }

      // Push event of the link-button
      if (target.classList.contains('btn')) {
        this.$gtm.push({
          event: 'user action',
          gaEventCategory: 'buttonclick',
          gaEventAction: this.$store.getters.title || document.title, // PageTitle
          gaEventLabel: this.$route.path, // PagePath
          gaEventValue: '1' // Fest =  1
        })
      }
    },
    navigate (event) {
      let target = event.target
      let i = 0

      if (this.$gtm) {
        this.gaEventsHandler(event)
      }

      // Go throught 5 parents max to find a tag
      while (
        i < 5 &&
        !(target instanceof HTMLAnchorElement) &&
        target.parentNode
      ) {
        target = target.parentNode
        i++
      }
      // If target is still not a link, ignore
      if (!(target instanceof HTMLAnchorElement)) return
      return this.redirect(target)
    },
    redirect (target) {
      const href = target.getAttribute('href')
      // Get link target, if local link, navigate with router link
      if (href && href[0] === '/') {
        event.preventDefault()
        this.$router.push(href)
      }
    },
    contentUpdated () {
      this.removeListeners()
      this.$nextTick(() => {
        this.addListeners()
      })
    },
    addListeners () {
      this._links = this.$el.getElementsByTagName('a')
      for (let i = 0; i < this._links.length; i++) {
        this._links[i].addEventListener('click', this.navigate, false)
      }
    },
    removeListeners () {
      if (this._links) {
        for (let i = 0; i < this._links.length; i++) {
          this._links[i].removeEventListener('click', this.navigate, false)
        }
        this._links = []
      }
    }
  }
}
</script>

<style lang="scss" module>
  @import "../../styles/shared";

  ul {
    margin: 0.8rem 0;
  }

  .content {
    :global a:not(.btn) {
        border-bottom: 2px solid $color-brand-secondary;
        transition: border $transition-duration $transition-function;
        &:hover {
          border-bottom: 2px solid $color-brand-primary;
        }
    }
  }
</style>
