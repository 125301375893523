/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'star': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<path pid="0" d="M18.75 7.125h-6.719L10 .875l-2.031 6.25H1.25l5.469 3.75-2.11 6.25L10 13.219l5.39 3.906-2.109-6.25 5.469-3.75z" _fill="#000" _stroke="#000" stroke-linejoin="round"/>'
  }
})
