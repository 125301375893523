<template>
  <img
    :src="require('../../assets/images/robomow-logo.svg')"
    :alt="$attrs.alt || 'Robomow Logo'"
  >
</template>

<script>

export default {
  name: 'UiLogo'
}
</script>
