/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tick': {
    width: 22,
    height: 16,
    viewBox: '0 0 22 16',
    data: '<path pid="0" d="M21.45 2.63L8.31 15.76.55 8a1.414 1.414 0 112-2l5.78 5.78L19.47.65a1.414 1.414 0 012 2l-.02-.02z" _fill="#fff"/>'
  }
})
