// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._32RG{position:fixed;z-index:30;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.5);display:flex;align-items:center;justify-content:center}._1wFz{position:relative;width:100%;max-width:90rem;margin:0 1.5rem}@media (min-width:48em){._1wFz{margin:0 4rem}}.video-content svg{fill:#fcd700;cursor:pointer}._2gjp{position:absolute;top:-5rem;right:-1.5rem;padding:0 1.5rem;height:5rem;display:flex;align-items:center;cursor:pointer}._2gjp svg{fill:#fff;pointer-events:none;transition:inherit}._2gjp:hover svg{fill:#fcd700}.video-content svg:hover{fill:#fff;transform:scale(1.1)}._3nLr{position:relative;z-index:2;padding-bottom:56.25%;padding-top:0;height:0;overflow:hidden;transform:translateZ(0);background:#fff}._3nLr iframe,._3nLr video{position:absolute;top:0;left:0;width:100%;height:100%;overflow:hidden}._158-{width:100%;height:100%;padding:.8rem 1rem;background:#fff;display:flex;justify-content:center;align-items:center;position:absolute;bottom:0;left:0;z-index:1;text-align:center;cursor:pointer}._158- span{color:#fcd700;text-decoration:underline}html[dir=rtl] ._2gjp{right:auto;left:-1.5rem}", ""]);
// Exports
exports.locals = {
	"overlay": "_32RG",
	"overlayInner": "_1wFz",
	"overlayClose": "_2gjp",
	"overlayInnerVideo": "_3nLr",
	"cookiePop": "_158-"
};
module.exports = exports;
